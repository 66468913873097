import ConfigService from '../ConfigService';
import EntityDataAccessService from '../GenericEntity/EntityDataAccessService';
import ApiService from '../HttpClient/ApiService';
import IKiosk from './IKiosk';

export default class KioskDataAccessService implements EntityDataAccessService<IKiosk> {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getAllEntities = ({
    operatorId,
    companyId,
    organizationId
  }: {
    operatorId?: string;
    organizationId?: string;
    companyId?: string;
  }): Promise<IKiosk[]> => {
    const requestParams = new URLSearchParams();
    operatorId && requestParams.append('operatorId', operatorId);
    companyId && requestParams.append('companyId', companyId);
    organizationId && requestParams.append('organizationId', organizationId);
    return this.apiService.request<IKiosk[]>('kiosk', 'GET', requestParams);
  };

  getEntityById = (id: string): Promise<IKiosk> => this.apiService.request<IKiosk>(`kiosk/${id}`, 'GET');

  createEntity = (data: Partial<IKiosk>): Promise<IKiosk> =>
    this.apiService.request<IKiosk>('kiosk', 'POST', undefined, data);

  deleteEntity = (id: string): Promise<void> => this.apiService.request(`kiosk/${id}`, 'DELETE');

  updateEntity = (data: Partial<IKiosk>): Promise<IKiosk> => {
    if (!data.kiosk_ID) throw new Error("Entity must have a 'kiosk_ID' to be updated");
    return this.apiService.request<IKiosk>(`kiosk/${data.kiosk_ID}`, 'PUT', undefined, data);
  };
}
