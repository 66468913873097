import ConfigService from '../ConfigService';
import EntityDataAccessService from '../GenericEntity/EntityDataAccessService';
import ApiService from '../HttpClient/ApiService';
import IOperator from './IOperator';

export default class OperatorService implements EntityDataAccessService<IOperator> {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getAllEntities = (): Promise<IOperator[]> => this.apiService.request<IOperator[]>('operators', 'GET');

  getEntityById = (id: string): Promise<IOperator> => this.apiService.request<IOperator>(`operators/${id}`, 'GET');

  createEntity = (data: Partial<IOperator>): Promise<IOperator> =>
    this.apiService.request<IOperator>('operators', 'POST', undefined, data);

  deleteEntity = (id: string): Promise<void> => this.apiService.request(`operators/${id}`, 'DELETE');

  updateEntity = (data: Partial<IOperator>): Promise<IOperator> => {
    if (!data._id) throw new Error("Entity must have an 'id' to be updated");
    return this.apiService.request<IOperator>(`operators/${data._id}`, 'PUT', undefined, data);
  };

  deleteEntities = (ids: string[]): Promise<void> => {
    console.log(`Deleting Operators: ${ids.join(', ')}`);
    const body = {
      ids
    };
    return this.apiService.request(`operators`, 'DELETE', undefined, body);
  };
}
