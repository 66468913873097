import { Card } from 'react-bootstrap';
import { BottlePricing, RefillPricing } from '../../../../common/Kiosk/KioskSettings';
import { BottlePricingFieldConfig, RefillPricingFieldConfig } from '../Fields/KioskSettingsFieldConfig';
import { useContext } from 'react';
import { KioskSettingsContext } from './KioskSettingsContext';
import KioskEditStrings from '../KioskEditStrings';
import KioskType from '../../../../common/Kiosk/KioskType';
import FieldRenderer from '../../../../components/Form/FormBuilder/FieldRenderer';
import { FieldKeys } from '../../../../components/Form/FormBuilder/FieldConfig';

const KioskDrinkPricingEdit: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);

  const defaultBottlePricing: BottlePricing = {
    base: 3,
    caffeine: 0.15,
    flavor: 0.25
  };

  const defaultRefillPricing: RefillPricing = {
    base: 0,
    caffeine: 0.15,
    flavor: 0.99,
    preAuthCharge: 5,
    minimumCharge: 0.15,
    sparkling: 0.5
  };

  const updateBottlePricing = (key: FieldKeys<BottlePricing>, value: number) => {
    const currentPricing = kioskSettings?.state.settings?.bottlePricing || defaultBottlePricing;
    kioskSettings?.handleSettingsChange('bottlePricing', {
      ...currentPricing,
      [key]: value
    });
  };

  const updateRefillPricing = (key: FieldKeys<RefillPricing>, value: number) => {
    const currentPricing = kioskSettings?.state.settings?.refillPricing || defaultRefillPricing;
    kioskSettings?.handleSettingsChange('refillPricing', {
      ...currentPricing,
      [key]: value
    });
  };

  const handleDecimalInputChange = (value: string) => Number(parseFloat(value).toFixed(2));

  const kioskType = kioskSettings?.state?.settings?.installInfo?.kioskType;

  return (
    <>
      {kioskType === KioskType.Duo && (
        <>
          <Card.Subtitle style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
            {KioskEditStrings.BottlePricingLabel}
          </Card.Subtitle>
          <div>
            {BottlePricingFieldConfig.map((field) => (
              <div key={field.key} className='col s12 m4'>
                <FieldRenderer
                  field={field}
                  value={kioskSettings?.state?.settings?.bottlePricing?.[field.key] || 0}
                  onChange={(key, val) => updateBottlePricing(key, handleDecimalInputChange(val))}
                  disabled={!kioskSettings?.deviceStatus.ready}
                  inputProps={{
                    step: '0.01'
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}
      <Card.Subtitle style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
        {KioskEditStrings.RefillPricingLabel}
      </Card.Subtitle>
      {kioskType &&
        RefillPricingFieldConfig.filter(
          (field) => field.kioskTypes.length === 0 || field.kioskTypes.includes(kioskType)
        ).map((field) => (
          <div key={field.key} className='col s12 m4'>
            <FieldRenderer
              field={field}
              value={kioskSettings?.state?.settings?.refillPricing?.[field.key] || 0}
              onChange={(key, val) => updateRefillPricing(key, handleDecimalInputChange(String(val)))}
              disabled={!kioskSettings?.deviceStatus.ready}
              inputProps={{
                step: '0.01'
              }}
            />
          </div>
        ))}
    </>
  );
};

export default KioskDrinkPricingEdit;
