import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import AuthGuard from '../../../../components/auth/AuthGuard';
import UserRole from '../../../../common/UserRole';
import { useAppSelector } from '../../../../hooks/storeHooks';
import useReports from '../../../../services/Reports/useReports';
import AnyDataTable from '../../../../components/table/AnyDataTable';
import IncidentReportData from '../IncidentReportData';
import IncidentReportHelper from '../IncidentReportHelper';
import IncidentReportStrings from '../IncidentReportStrings';
import IconLoading from '../../../../components/ui/Icons/IconLoading';
import IncidentReportHeader from './IncidentReportHeader';
import IncidentResolutionStatusSelect from '../IncidentResolutionStatusSelect';
import IncidentResolutionStatus from '../IncidentResolutionStatus';
import IMessage from '../../../../common/Notifications/IMessage';
import IncidentMessages from './IncidentMessages';

interface IIncidentReport {}

interface IncidentReportState {
  data?: IncidentReportData;
  loading: boolean;
  error?: string;
  displayChatWidget: boolean;
  messages: IMessage[];
}

const IncidentReport: React.FC<IIncidentReport> = () => {
  const initialState: IncidentReportState = {
    data: undefined,
    loading: true,
    error: '',
    displayChatWidget: false,
    messages: []
  };
  const [state, setState] = useState<IncidentReportState>(initialState);
  const user = useAppSelector((store) => store.auth?.user);
  const { getIncidentReport, updateIncidentResolution } = useReports();
  const params = useParams<{ correlationId?: string }>();

  const fetchReport = useCallback(
    async (correlationId: string) => {
      try {
        const report = await getIncidentReport(correlationId);
        setState((prevState) => ({
          ...prevState,
          data: report,
          error: undefined,
          loading: false
        }));
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          error: `${IncidentReportStrings.ErrorFetchingReports}: ${
            error instanceof Error ? error.message : JSON.stringify(error)
          }`,
          loading: false
        }));
      }
    },
    [getIncidentReport]
  );

  useEffect(() => {
    if (params.correlationId) {
      fetchReport(params.correlationId);
    }
  }, [params, getIncidentReport, fetchReport]);

  const handleResolutionChange = async (newResolution: IncidentResolutionStatus, sendUpdateToRemote: boolean) => {
    try {
      if (state.loading) return;
      if (params.correlationId) {
        setState((prevState) => ({
          ...prevState,
          loading: true
        }));

        const update = sendUpdateToRemote
          ? await updateIncidentResolution(params.correlationId, newResolution)
          : ({ ...state.data, resolution: newResolution } as IncidentReportData);

        setState((prevState) => ({
          ...prevState,
          data: update,
          loading: false
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: `${IncidentReportStrings.ErrorUpdatingIncident}: ${
          error instanceof Error ? error.message : JSON.stringify(error)
        }`,
        loading: false
      }));
    }
  };

  const toggleChatWidget = () => {
    setState((prevState) => ({
      ...prevState,
      displayChatWidget: !prevState.displayChatWidget
    }));
  };

  if (!user?.role) return null;

  return (
    <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user?.role}>
      <div style={{ padding: '10px' }}>
        <IncidentReportHeader resolution={state.data?.resolution} onClickMessageButton={toggleChatWidget} />
        <div className='row'>
          <div className='col s12 m4' style={{ padding: 0 }}>
            {state.data?.resolution && (
              <IncidentResolutionStatusSelect
                value={state.data?.resolution}
                onChange={(status) => handleResolutionChange(status, true)}
              />
            )}
          </div>
        </div>
        {state.loading && <IconLoading width={50} height={50} />}
        {state.error && <span style={{ color: 'red' }}>{state.error}</span>}
        {state.data &&
          IncidentReportHelper.getReportSections(state.data).map(({ title, dataPath }) => {
            const data = dataPath();
            if (!dataPath) return null;
            return (
              <div key={title}>
                <h5 style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>{title}</h5>
                <AnyDataTable data={data} />
              </div>
            );
          })}
      </div>
      <IncidentMessages
        displayChatWidget={state.displayChatWidget}
        toggleChatWidget={toggleChatWidget}
        correlationId={params.correlationId}
        incidentId={state.data?.id}
        initialIncidentNotification={state.data?.eventNotification}
        incidentResolution={state.data?.resolution}
        onResolutionChange={(status) => handleResolutionChange(status, false)}
        kioskId={state.data?.kioskId}
      />
    </AuthGuard>
  );
};

export default IncidentReport;
