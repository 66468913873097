import React, { useMemo } from 'react';
import EntityForm from '../../../EntityManagement/EntityForm/EntityForm';
import { useKioskFormConfig } from '../../KioskForm/useKioskFormConfig';
import { useEntityState } from '../../../EntityManagement/context/useEntityState';
import KioskDataAccessService from '../../../../services/Kiosk/KioskDataAccessService';
import EntityType from '../../../EntityManagement/EntityType';
import { EntityManagementContextProvider } from '../../../EntityManagement/context/EntityManagementContext';
import IKiosk from '../../../../services/Kiosk/IKiosk';

interface IKioskEntityEdit {
  kioskId: string;
}

const KioskEntityEdit: React.FC<IKioskEntityEdit> = ({ kioskId }) => {
  const memoizedKioskId = useMemo(() => kioskId, [kioskId]);
  const kioskDataAccessService = useMemo(() => new KioskDataAccessService(), []);
  const entityEdit = useEntityState({
    service: kioskDataAccessService,
    entityId: memoizedKioskId,
    entityType: EntityType.Kiosk
  });
  const formConfig = useKioskFormConfig({ excludeKeys: ['kiosk_ID'] });

  return (
    <EntityManagementContextProvider value={{ ...entityEdit, entityType: EntityType.Kiosk }}>
      <EntityForm<IKiosk> mode='edit' fieldConfig={formConfig} />
    </EntityManagementContextProvider>
  );
};

export default KioskEntityEdit;
