import { useState, useCallback } from 'react';
import ICompany from '../../../services/Company/ICompany';
import useCompanyFormConfig from './useCompanyFormConfig';
import useResetEntityRelationships from '../../EntityManagement/EntityForm/useEntityRelationshipReset';

export const useCompanyFormState = (initialEntity?: Partial<ICompany>) => {
  const [formState, setFormState] = useState<Partial<ICompany> | undefined>(initialEntity);
  const formConfig = useCompanyFormConfig(formState);

  const handleFieldChange = useCallback((updatedFormState: Partial<ICompany>) => {
    setFormState((prevState) => ({ ...prevState, ...updatedFormState }));
  }, []);

  const handleBeforeStateUpdate = useResetEntityRelationships<ICompany>();

  return { formState, formConfig, handleFieldChange, handleBeforeStateUpdate };
};
