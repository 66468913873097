import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory from 'react-bootstrap-table2-filter';
import Dropdown from 'react-dropdown';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import TableTotals from './TableTotals';
import DataColumn from './DataColumn';
import IconLoading from '../ui/Icons/IconLoading';
import { tableRowStyle } from './tableRowStyle';
import { useEffect, useState } from 'react';
import TablePaginationLinks from './TablePagination';
import styles from './DataTable.module.css';
import rgbaToRgbString from '../../utils/rgbaToRgbString';
import ThemeConfig from '../../theme/ThemeConfig';

interface IExpandingRow<T> {
  renderer: (row: T) => JSX.Element | undefined;
  onlyOneExpanding: boolean;
}

export interface IRowSelectionConfig<T> {
  inputType: 'checkbox' | 'radio';
  onRowSelectionChange: (selectedKeys: Array<keyof T>) => void;
}

interface IDataTable<T, K extends keyof T | string> {
  totalSize: number;
  contextName: string;
  dataColumnConfig: DataColumn<T, K>[];
  data: unknown;
  loading: boolean;
  expandingRow?: IExpandingRow<T>;
  rowEvents?: { onClick: (_e: React.MouseEvent, row: T) => void };
  keyField?: string;
  rowSelectionConfig?: IRowSelectionConfig<T>;
}

function DataTable<T, K extends keyof T | string>(props: IDataTable<T, K>) {
  const {
    totalSize,
    contextName,
    dataColumnConfig,
    data,
    loading,
    expandingRow,
    rowEvents,
    keyField = '_id',
    rowSelectionConfig
  } = props;

  const [state, setState] = useState<{ pageCountSelected: number; rowsSelected: Array<keyof T> }>({
    pageCountSelected: 50,
    rowsSelected: []
  });
  const options = {
    sizePerPage: state.pageCountSelected,
    custom: true,
    totalSize: totalSize,
    pageButtonRenderer: TablePaginationLinks,
    paginationTotalRenderer: (from: number, to: number, size: number) => (
      <TableTotals from={from} to={to} size={size} contextLabel={contextName} />
    )
  };
  const pageCount = ['10', '20', '50', '100'];
  const handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
    onSizePerPageChange(newSizePerPage, page);
  };
  const onSelectPageCount = (value: number, paginationProps, length: number) => {
    paginationProps.page = 1;
    setState({
      ...state,
      pageCountSelected: value
    });
    handleSizePerPage(paginationProps, value);
  };

  const onSelectRow = (row: T, isSelected: boolean) => {
    setState((prev) => ({
      ...prev,
      rowsSelected: isSelected
        ? [...prev.rowsSelected, row[keyField]]
        : prev.rowsSelected.filter((key) => key !== row[keyField])
    }));
  };

  const onSelectAllRows = (isSelected: boolean, rows: T[]) => {
    setState((prev) => ({
      ...prev,
      rowsSelected: isSelected ? rows.map((row) => row[keyField]) : []
    }));
  };

  useEffect(() => {
    if (rowSelectionConfig?.onRowSelectionChange) rowSelectionConfig.onRowSelectionChange(state.rowsSelected);
  }, [rowSelectionConfig, state.rowsSelected]);

  const rowSelection = rowSelectionConfig
    ? {
        mode: rowSelectionConfig?.inputType,
        clickToSelect: true,
        onSelect: onSelectRow,
        onSelectAll: onSelectAllRows,
        style: {
          backgroundColor: rgbaToRgbString(ThemeConfig.colors.secondary),
          color: rgbaToRgbString(ThemeConfig.colors.white),
          fontWeight: ThemeConfig.fontWeights.bold
        }
      }
    : undefined;

  return (
    <div className='row'>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <div className={`${[styles.DataTable].join(' ')} col s12 l12 m12 h-100 table-wrapper`}>
            <BootstrapTable
              keyField={keyField}
              style={{
                maxWidth: '100%'
              }}
              data={data}
              columns={dataColumnConfig}
              wrapperClasses='table-responsive'
              noDataIndication={
                loading ? (
                  <IconLoading width={50} height={50} />
                ) : (
                  `No ${contextName.toLocaleLowerCase()} for selected filters`
                )
              }
              rowStyle={tableRowStyle}
              expandRow={expandingRow}
              filter={filterFactory()}
              rowEvents={rowEvents}
              {...paginationTableProps}
              selectRow={rowSelection}
            />

            <PaginationTotalStandalone className='col l4' {...paginationProps} />
            <div className='per-page-select'>
              <div className='col l3 right-align orders-per-page'>
                <strong>{contextName} Per Page</strong>
              </div>
              <div className='col s2 l1 m2 select-page-count center-align'>
                <Dropdown
                  options={pageCount}
                  onChange={(option) => onSelectPageCount(+option.value, paginationProps, totalSize)}
                  value={state.pageCountSelected.toString()}
                />
              </div>
            </div>
            <PaginationListStandalone className='col l4' key={Math.random()} {...paginationProps} />
          </div>
        )}
      </PaginationProvider>
    </div>
  );
}

export default DataTable;
