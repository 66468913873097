import GenericEntity from '../../../services/GenericEntity/GenericEntity';
import IEntityManagementContext from '../context/IEntityManagementContext';
import EntityType from '../EntityType';

/**
 * Utility to map a parent entity to an initial entity with relationship fields.
 *
 * @template T - The target entity type (e.g., ICompany).
 * @template ParentType - The type of the parent entity's form state.
 * @param parentEntity - The parent entity context.
 * @param relationships - A mapping of EntityType to the relationship field and the key from formState to use.
 * @returns Partial<T> with pre-filled relationship fields based on the parent entity.
 */
const mapParentToInitialEntity = <T, ParentType extends GenericEntity>(
  parentEntity: IEntityManagementContext<ParentType> | null,
  relationships: Partial<Record<EntityType, { targetField: keyof T; sourceField: keyof ParentType }>>
): Partial<T> => {
  const initialEntity: Partial<T> = {};

  if (!parentEntity || !parentEntity.state.formState || !parentEntity.entityType) {
    return initialEntity;
  }

  const relationship = relationships[parentEntity.entityType];
  if (relationship) {
    const { targetField, sourceField } = relationship;
    const formState = parentEntity.state.formState;

    if (formState[sourceField]) {
      initialEntity[targetField] = formState[sourceField] as any;
    }
  }

  return initialEntity;
};

/**
 * Maps inherited relationship fields from a parent entity to a new entity.
 *
 * Ensures that relationship references (organization, operator, company) are
 * properly inherited from the parent context when creating child entities.
 *
 * This function also preserves any existing relationships in the target entity.
 *
 * @param parentEntity - The context containing the parent entity's state and metadata
 * @param entity - The partial entity being created, may already contain some relationship values
 *
 * @returns A new partial entity with inherited relationship fields from the parent
 *
 */
const mapInheritedRelationships = <T, ParentType extends GenericEntity>(
  entity: Partial<T>,
  parentEntity: IEntityManagementContext<ParentType> | null
): Partial<T> => {
  const inheritableRelationshipFields = ['organization', 'operator', 'company'];

  if (!parentEntity?.state.entity) {
    return entity;
  }

  return inheritableRelationshipFields.reduce(
    (acc, field) => {
      const parentValue = parentEntity.state.entity?.[field as keyof ParentType];
      if (parentValue && !(field in acc)) {
        acc[field as keyof T] = parentValue as any;
      }
      return acc;
    },
    { ...entity }
  );
};

/**
 *
 * @template T - The target entity type (e.g., ICompany).
 * @template ParentType - The type of the parent entity's form state.
 * @param parentEntity - The parent entity context.
 * @param relationships - A mapping of EntityType to the relationship field and the key from formState to use.
 * @returns Partial<T> with pre-filled relationship fields based on the parent entity.
 */
const buildInitialEntity = <T, ParentType extends GenericEntity>(
  parentEntity: IEntityManagementContext<ParentType> | null,
  relationships: Partial<Record<EntityType, { targetField: keyof T; sourceField: keyof ParentType }>>
): Partial<T> => {
  const entityMappedToParent = mapParentToInitialEntity(parentEntity, relationships);
  const entityMappedToOtherRelationships = mapInheritedRelationships(entityMappedToParent, parentEntity);
  return entityMappedToOtherRelationships;
};

export default buildInitialEntity;
