import React from 'react';
import OperatorService from '../../../services/Operators/OperatorService';
import { OperatorGeneralFieldConfig } from '../OperatorEdit/OperatorFieldConfig';
import EntityCreate from '../../EntityManagement/EntityCreate/EntityCreate';
import IOperator from '../../../services/Operators/IOperator';
import EntityType from '../../EntityManagement/EntityType';
import IEntityCreateComponent from '../../EntityManagement/EntityCreate/IEntityCreateComponent';

const OperatorCreate: React.FC<IEntityCreateComponent<IOperator>> = ({ onSuccess }) => {
  const initialOperator = {};

  return (
    <EntityCreate<IOperator>
      service={new OperatorService()}
      formConfig={OperatorGeneralFieldConfig}
      initialEntity={initialOperator}
      redirectOnSubmitPath='operators'
      entityType={EntityType.Operator}
      onSuccess={onSuccess}
    />
  );
};

export default OperatorCreate;
