import UserRole from '../../../common/UserRole';
import { FieldConfig } from '../../../components/Form/FormBuilder/FieldConfig';
import FieldConfigUtility from '../../../components/Form/FormBuilder/FieldConfigUtility';
import ICompany from '../../../services/Company/ICompany';
import IKiosk from '../../../services/Kiosk/IKiosk';
import IOperator from '../../../services/Operators/IOperator';
import IOrganization from '../../../services/Organizations/IOrganization';
import IUser from '../../../services/User/IUser';
import kebabToTitleCase from '../../../utils/kebabToTitleCase';
import selectableUserRoles from '../../../common/Permissions/selectableUserRoles';
import UserEntityStrings from '../UserEntityStrings';

export const UserFormConfig = ({
  operators,
  organizations,
  companies,
  excludeKeys,
  includeKeys,
  kiosks,
  loggedInUserRole
}: {
  organizations?: IOrganization[];
  operators?: IOperator[];
  companies?: ICompany[];
  excludeKeys?: Array<keyof IUser>;
  includeKeys?: Array<keyof IUser>;
  kiosks?: IKiosk[] | undefined;
  loggedInUserRole?: UserRole;
}): FieldConfig<IUser>[] => {
  const roleOptions = selectableUserRoles(loggedInUserRole)?.map((role) => ({
    label: kebabToTitleCase(role),
    value: role
  }));

  const fields: FieldConfig<IUser>[] = [
    { key: 'name', required: true, type: 'string' },
    { key: 'email', required: true, type: 'string' },
    { key: 'phone_number', required: false, type: 'string' },
    { key: 'country', required: false, type: 'string' },
    { key: 'state', required: false, type: 'string' },
    { key: 'city', required: false, type: 'string' },
    { key: 'password', required: true, type: 'password' }
  ];

  if (loggedInUserRole && roleOptions && roleOptions?.length > 0) {
    fields.push({
      key: 'role',
      required: true,
      type: 'select',
      options: roleOptions
    });
  }

  if (operators && operators.length > 0) {
    fields.push({
      key: 'operator',
      required: false,
      type: 'select',
      options: FieldConfigUtility.createFieldOptions<IOperator>('_id', 'name', operators)
    });
  }

  if (organizations && organizations.length > 0) {
    fields.push({
      key: 'organization',
      required: false,
      type: 'select',
      options: FieldConfigUtility.createFieldOptions<IOrganization>('_id', 'name', organizations)
    });
  }

  if (companies && companies.length > 0) {
    fields.push({
      key: 'company',
      required: false,
      type: 'select',
      options: FieldConfigUtility.createFieldOptions<ICompany>('_id', 'name', companies)
    });
  }

  if (kiosks && kiosks.length > 0) {
    fields.push({
      key: 'kioskIds',
      label: 'Kiosk Selection',
      description: UserEntityStrings.KioskSelectDescription,
      required: false,
      type: 'checkbox-list',
      options: FieldConfigUtility.createFieldOptions<IKiosk>('_id', 'alias', kiosks),
      renderCondition: (formState) =>
        formState.role !== undefined && [UserRole.MachineAdmin, UserRole.User].includes(formState.role)
    });
  }

  if (includeKeys) {
    return fields.filter((field) => includeKeys.includes(field.key as keyof IUser));
  }

  if (excludeKeys) {
    return fields.filter((field) => !excludeKeys.includes(field.key as keyof IUser));
  }

  return fields;
};
