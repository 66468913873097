import React from 'react';
import EntityForm from '../../EntityManagement/EntityForm/EntityForm';
import IUser from '../../../services/User/IUser';
import { FieldConfig } from '../../../components/Form/FormBuilder/FieldConfig';
import { useAppSelector } from '../../../hooks/storeHooks';
import SectionHeading from '../../../components/Heading/SectionHeading/SectionHeading';
import KioskAccessGenerator from './UserSecurity/KioskAccess/KioskAccessGenerator';
import UserEntityStrings from '../UserEntityStrings';

interface IUserSecurity {
  userId?: string;
}

const UserSecurity: React.FC<IUserSecurity> = ({ userId }) => {
  const loggedInUser = useAppSelector((store) => store.auth?.user);
  const accountIsLoggedInUser = loggedInUser.uuid === userId;

  const passwordField: FieldConfig<IUser> = {
    key: 'password',
    type: 'password',
    required: true
  };

  return (
    <>
      <SectionHeading heading={UserEntityStrings.DashboardAccessTitle} />
      <EntityForm<IUser> mode='edit' fieldConfig={[passwordField]} />
      {accountIsLoggedInUser && (
        <>
          <SectionHeading heading={UserEntityStrings.KioskAccessTitle} />
          <KioskAccessGenerator />
        </>
      )}
    </>
  );
};
export default UserSecurity;
