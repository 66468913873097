import { AppBar, Tab } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import PageHeading from '../../../components/Heading/PageHeading';
import { useEntityState } from '../context/useEntityState';
import EntityDataAccessService from '../../../services/GenericEntity/EntityDataAccessService';
import { EntityManagementContextProvider } from '../context/EntityManagementContext';
import styles from './EntityEditScreen.module.css';
import EntityType from '../EntityType';
import stringWithSpacesUppercase from '../../../utils/stringWithSpacesUppercase';

interface TabConfig {
  label: string;
  key: string;
  component: JSX.Element;
}

export interface IEntityManagementScreen<T> {
  entityType: EntityType;
  entityId: string;
  tabConfig: TabConfig[];
  service: EntityDataAccessService<T>;
  headingKey: keyof T;
  displayBackButton?: boolean;
  /**
   * Optional hook to update entity state after a field change event but before the `formState` updates.
   *
   * Useful for modifying the temp state of the entity using other properties of T.
   */
  onBeforeStateUpdate?: (key: keyof T, value: any, entityState: T) => T;
}

const EntityEditScreen = <T,>({
  entityId,
  tabConfig,
  service,
  entityType,
  headingKey,
  displayBackButton,
  onBeforeStateUpdate
}: IEntityManagementScreen<T>) => {
  const entityEdit = useEntityState({ service, entityId, entityType, onBeforeStateUpdate });
  const headingValue = entityEdit?.state?.entity?.[headingKey] as string | undefined;

  return (
    <EntityManagementContextProvider value={{ ...entityEdit, entityType }}>
      <div className={styles.EntityManagementScreen}>
        <div>
          <PageHeading
            heading={`${stringWithSpacesUppercase(entityType)} Details${headingValue ? `: ${headingValue}` : ''}`}
            displayBackButton={displayBackButton}
          />
          <TabContext value={entityEdit.state.currentTab}>
            <AppBar position='static' className={styles.appBar}>
              <TabList
                onChange={(_, index) => entityEdit.switchTab(index)}
                aria-label={`${stringWithSpacesUppercase(entityType)} Edit Tabs`}
              >
                {tabConfig.map((tab) => (
                  <Tab key={tab.key} label={tab.label} value={tab.key} />
                ))}
              </TabList>
            </AppBar>
            {tabConfig.map((tab) => (
              <TabPanel key={tab.key} value={tab.key} style={{ padding: 0 }}>
                <div className={styles.tabPanel}>{tab.component}</div>
              </TabPanel>
            ))}
          </TabContext>
        </div>
      </div>
    </EntityManagementContextProvider>
  );
};

export default EntityEditScreen;
