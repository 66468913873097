import { textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import DataColumn from '../../../components/table/DataColumn';
import IOperator from '../../../services/Operators/IOperator';
import OperatorColumnKeys from './OperatorColumnKeys';

const OperatorsTableConfig = (): DataColumn<IOperator, OperatorColumnKeys>[] => {
  return [
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter()
    },
    {
      dataField: 'city',
      text: 'City',
      filter: textFilter()
    },
    {
      dataField: 'state',
      text: 'State',
      filter: textFilter()
    },
    {
      dataField: 'updatedAt',
      text: 'Last Updated',
      sort: false,
      formatter: (cell: string) => (cell ? new Date(cell).toLocaleDateString('en-US') : 'No updates'),
      filter: dateFilter({})
    }
  ];
};

export default OperatorsTableConfig;
