import React, { useMemo, useState } from 'react';
import UsersTableConfig from './UsersTable/UsersTableConfig';
import IUser from '../../services/User/IUser';
import UserColumnKeys from './UsersTable/UserColumnKeys';
import EntityList from '../EntityManagement/EntityList/EntityList';
import UserService from '../../services/User/UserService';
import EntityType from '../EntityManagement/EntityType';
import UserCreate from './UserCreate/UserCreate';

interface IUsersList {
  companyId?: string;
  organizationId?: string;
  operatorId?: string;
  heading?: string;
}

const UsersList: React.FC<IUsersList> = ({ heading, operatorId, organizationId, companyId }) => {
  const [kioskIds, setKioskIds] = useState<string[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);

  const dataColumnConfig = useMemo(
    () => UsersTableConfig({ allKioskIds: kioskIds, allCompanies: companies }),
    [kioskIds, companies]
  );

  const handleDataChange = (localEntities: IUser[]) => {
    const newKioskIds = Array.from(new Set(localEntities.flatMap((user) => user.kioskIds || [])));

    const newCompanies = Array.from(
      localEntities
        .filter((user) => user.company && typeof user.company === 'object')
        .map((user) => (typeof user.company === 'object' && user.company.name ? user.company.name : null))
        .filter((e) => e !== null)
    );

    if (JSON.stringify(newKioskIds) !== JSON.stringify(kioskIds)) {
      setKioskIds(newKioskIds);
    }

    if (JSON.stringify(newCompanies) !== JSON.stringify(companies)) {
      setCompanies(newCompanies as string[]);
    }
  };

  return (
    <EntityList<IUser, UserColumnKeys>
      entityService={new UserService()}
      entityType={EntityType.User}
      heading={heading}
      dataColumnConfig={dataColumnConfig}
      createComponent={UserCreate}
      sortKey='name'
      filterOptions={{
        operatorId,
        companyId,
        organizationId
      }}
      rowLinkConfig={{
        path: 'users',
        suffix: 'edit',
        key: 'uuid'
      }}
      onDataChange={handleDataChange}
    />
  );
};

export default UsersList;
