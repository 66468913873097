import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import NavigateBeforeTwoTone from '@material-ui/icons/NavigateBeforeTwoTone';

const BackButton: React.FC = () => {
  const history = useHistory();

  const canGoBack = history.length > 1;

  const handleGoBack = () => {
    history.goBack();
  };

  if (!canGoBack) return null;

  return (
    <IconButton onClick={handleGoBack} aria-label='back'>
      <NavigateBeforeTwoTone />
    </IconButton>
  );
};

export default BackButton;
