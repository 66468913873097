import { useEffect, useMemo } from 'react';
import useOperatorData from '../../../services/Operators/useOperatorData';
import useOrganizationData from '../../../services/Organizations/useOrganizationData';
import useCompanies from '../../../services/Company/useCompany';
import useKiosksData from '../../../services/Kiosk/useKiosks';
import { UserFormConfig } from './UserFormConfig';
import IUser from '../../../services/User/IUser';
import { useAppSelector } from '../../../hooks/storeHooks';
import UserRole from '../../../common/UserRole';

const useUserFormConfig = ({
  excludeKeys,
  formState,
  includeKeys
}: {
  formState: Partial<IUser> | null;
  excludeKeys?: Array<keyof IUser>;
  includeKeys?: Array<keyof IUser>;
}) => {
  const user = useAppSelector<IUser>((store) => store.auth?.user);
  const { operators } = useOperatorData();
  const { organizations, fetchNewOrganizations } = useOrganizationData({
    operatorId: formState?.operator as unknown as string
  });
  const { companies, fetchNewCompanies } = useCompanies({
    operatorId: formState?.operator as unknown as string,
    organizationId: formState?.organization as unknown as string
  });
  const kiosks = useKiosksData({
    companyOnlyKiosks: true,
    companyObjectId: formState?.company as string
  });

  useEffect(() => {
    if (formState?.operator) fetchNewOrganizations();
    if (formState?.organization || formState?.operator) fetchNewCompanies();
  }, [formState?.operator, formState?.organization, fetchNewOrganizations, fetchNewCompanies]);

  return useMemo(
    () =>
      UserFormConfig({
        operators: user.role === UserRole.DropSuperAdmin ? operators : [],
        organizations: user.role === UserRole.DropSuperAdmin ? organizations : [],
        companies: user.role === UserRole.DropSuperAdmin ? companies : [],
        excludeKeys,
        kiosks: kiosks,
        includeKeys,
        loggedInUserRole: user.role
      }),
    [user.role, operators, organizations, companies, excludeKeys, kiosks, includeKeys]
  );
};

export default useUserFormConfig;
