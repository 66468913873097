import { ReactNode, useEffect, useState } from 'react';
import { Dialog as MaterialDialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IDialog {
  display: boolean;
  onClose: () => void;
  heading?: string;
  children: ReactNode;
}

const Dialog: React.FC<IDialog> = ({ onClose, heading, children, display }) => {
  const [shouldDisplay, setShouldDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (display) setShouldDisplay(display);
    return () => {
      setShouldDisplay(false);
    };
  }, [display]);

  return (
    <MaterialDialog open={shouldDisplay} onClose={onClose}>
      {heading && <DialogTitle>{heading}</DialogTitle>}
      {children && <DialogContent>{children}</DialogContent>}
    </MaterialDialog>
  );
};

export default Dialog;
