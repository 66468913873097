import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import PageHeading from '../../../components/Heading/PageHeading';
import KioskEditTabReference from './KioskEditTabReference';
import KioskSettingsEdit from './Settings/KioskSettingsEdit';
import { useKioskSettings } from '../../../services/Kiosk/useKioskSettings';
import { KioskSettingsContext } from './Settings/KioskSettingsContext';
import PeripheralCommunication from '../PeripheralCommunication/PeripheralCommunicationScreen';
import KioskOnlineStatus from '../KioskOnlineStatus';
import styles from './KioskEdit.module.css';
import UserRole from '../../../common/UserRole';
import { useAppSelector } from '../../../hooks/storeHooks';
import InitialKioskSettings from './Settings/InitialKioskSettings';
import KioskFeaturesEdit from './Features/KioskFeaturesEdit';
import KioskSystem from './System/KioskSystem';
import KioskAdvancedEdit from './Advanced/KioskAdvancedEdit';
import KioskEntityEdit from './General/KioskEntityEdit';
import Card from '../../../components/Card/Card';

const KioskEdit: React.FC = () => {
  const kioskSettings = useKioskSettings(InitialKioskSettings);
  const user = useAppSelector((store) => store.auth?.user);

  return (
    <div className={[styles.KioskEdit, 'valign-wrapper'].join(' ')} style={{ marginTop: '1%', width: '100%' }}>
      <div className='row' style={{ marginTop: '1%', width: '100%' }}>
        <div className={styles.headingContainer}>
          <PageHeading heading={`${kioskSettings.state.kioskId} Configuration`} displayBackButton />
          <KioskOnlineStatus online={kioskSettings.deviceStatus.online} loaded={kioskSettings.deviceStatus.loaded} />
        </div>
        <div>
          <div style={{ marginTop: '50px' }}>
            <KioskSettingsContext.Provider value={kioskSettings}>
              <TabContext value={kioskSettings.state.currentTab}>
                <AppBar position='static' style={{ backgroundColor: 'transparent', color: 'black' }}>
                  <TabList onChange={(_e, index) => kioskSettings.switchTab(index)} aria-label=''>
                    <Tab label='Settings' value={KioskEditTabReference.Settings} />
                    <Tab label='Features' value={KioskEditTabReference.Features} />
                    {user?.role === UserRole.DropSuperAdmin && (
                      <Tab label='Peripherals' value={KioskEditTabReference.PeripheralCommunication} />
                    )}
                    {user?.role === UserRole.DropSuperAdmin && (
                      <Tab label='System' value={KioskEditTabReference.System} />
                    )}
                    {user?.role === UserRole.DropSuperAdmin && (
                      <Tab label='General' value={KioskEditTabReference.General} />
                    )}
                    {user?.role === UserRole.DropSuperAdmin && (
                      <Tab label='Advanced' value={KioskEditTabReference.Advanced} />
                    )}
                  </TabList>
                </AppBar>
                <TabPanel value={KioskEditTabReference.Settings} style={{ padding: 0 }}>
                  <KioskSettingsEdit />
                </TabPanel>
                <TabPanel value={KioskEditTabReference.Features} style={{ padding: 0 }}>
                  <KioskFeaturesEdit />
                </TabPanel>
                {user?.role === UserRole.DropSuperAdmin && (
                  <TabPanel value={KioskEditTabReference.PeripheralCommunication} style={{ padding: 0 }}>
                    <PeripheralCommunication />
                  </TabPanel>
                )}
                {user?.role === UserRole.DropSuperAdmin && (
                  <TabPanel value={KioskEditTabReference.System} style={{ padding: 0 }}>
                    <KioskSystem />
                  </TabPanel>
                )}
                {user?.role === UserRole.DropSuperAdmin && (
                  <TabPanel value={KioskEditTabReference.Advanced} style={{ padding: 0 }}>
                    <KioskAdvancedEdit />
                  </TabPanel>
                )}
                {user?.role === UserRole.DropSuperAdmin && (
                  <TabPanel value={KioskEditTabReference.General} style={{ padding: 0 }}>
                    {kioskSettings.state.kioskId && (
                      <Card>
                        <KioskEntityEdit kioskId={kioskSettings.state.kioskId} />
                      </Card>
                    )}
                  </TabPanel>
                )}
              </TabContext>
            </KioskSettingsContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KioskEdit;
