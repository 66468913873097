import EntityCreate from '../../EntityManagement/EntityCreate/EntityCreate';
import CompanyService from '../../../services/Company/CompanyService';
import ICompany from '../../../services/Company/ICompany';
import IEntityCreateComponent from '../../EntityManagement/EntityCreate/IEntityCreateComponent';
import EntityType from '../../EntityManagement/EntityType';
import { useEntityManagementContext } from '../../EntityManagement/context/useEntityManagementContext';
import GenericEntity from '../../../services/GenericEntity/GenericEntity';
import buildInitialEntity from '../../EntityManagement/EntityCreate/buildInitialEntity';
import { useCompanyFormState } from '../CompanyForm/useCompanyFormState';

const CompanyCreate = <ParentType extends GenericEntity>({ onSuccess }: IEntityCreateComponent<ICompany>) => {
  const parentEntity = useEntityManagementContext<ParentType>();
  const initialEntity = buildInitialEntity<ICompany, ParentType>(parentEntity, {
    [EntityType.Operator]: { targetField: 'operator', sourceField: '_id' },
    [EntityType.Organization]: { targetField: 'organization', sourceField: '_id' }
  });
  const { formConfig, handleFieldChange, handleBeforeStateUpdate } = useCompanyFormState(initialEntity);

  return (
    <EntityCreate<ICompany>
      service={new CompanyService()}
      formConfig={formConfig}
      initialEntity={initialEntity}
      onSuccess={onSuccess}
      entityType={EntityType.Company}
      onFieldChange={handleFieldChange}
      onBeforeStateUpdate={handleBeforeStateUpdate}
    />
  );
};

export default CompanyCreate;
