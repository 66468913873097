import { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import DataColumn from '../../../../components/table/DataColumn';
import IKiosk from '../../../../services/Kiosk/IKiosk';
import KiosksColumnKeys from './KiosksColumnKeys';
import kebabToTitleCase from '../../../../utils/kebabToTitleCase';
import stringWithSpacesUppercase from '../../../../utils/stringWithSpacesUppercase';
import KioskStatus from '../../../../common/Kiosk/KioskStatus';

const kioskStatusOptions = Object.entries(KioskStatus).reduce((options, [key, value]) => {
  options[value] = stringWithSpacesUppercase(key);
  return options;
}, {});

interface KiosksTableConfigOptions {
  excludeKeys?: KiosksColumnKeys[];
}

const KiosksTableConfig = ({ excludeKeys }: KiosksTableConfigOptions): DataColumn<IKiosk, KiosksColumnKeys>[] => {
  const columns: DataColumn<IKiosk, KiosksColumnKeys>[] = [
    {
      dataField: 'kiosk_ID',
      text: 'ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'alias',
      text: 'Alias',
      filter: textFilter()
    },
    {
      dataField: 'location',
      text: 'Location',
      filter: textFilter()
    },
    {
      dataField: 'locationAlias',
      text: 'Location Alias',
      filter: textFilter()
    },
    {
      dataField: 'company',
      text: 'Company',
      formatter: (cell: IKiosk['company']) => {
        if (cell && typeof cell === 'object' && 'name' in cell) {
          return cell.name;
        }
        return cell || 'Not Assigned';
      }
    },
    {
      dataField: 'operator',
      text: 'Operator',
      formatter: (cell: IKiosk['operator']) => {
        if (cell && typeof cell === 'object' && 'name' in cell) {
          return cell.name;
        }
        return cell || 'Not Assigned';
      }
    },
    {
      dataField: 'kioskStatus',
      text: 'Kiosk Status',
      formatter: (cell: string) => cell && kebabToTitleCase(cell),
      filter: selectFilter({
        options: kioskStatusOptions,
        defaultValue: KioskStatus.Deployed
      })
    },
    {
      dataField: 'date_created',
      text: 'Created',
      sort: true,
      formatter: (cell: string) => (cell ? new Date(cell).toLocaleDateString('en-US') : ''),
      filter: dateFilter({})
    }
  ];

  if (excludeKeys) {
    return columns.filter((column) => !excludeKeys.includes(column.dataField as KiosksColumnKeys));
  }

  return columns;
};

export default KiosksTableConfig;
