import is from 'check-types';

export default class RestApiError extends Error {
  constructor(public status: string | number, public details: string, public path?: string) {
    super(RestApiError.createMessage(status, details));
  }

  private static createMessage = (status: string | number, details: string): string => {
    if (status === details) {
      return `An error occurred whilst processing your request: ${details}`;
    }
    return `An error occurred whilst processing your request: ${status} - ${
      is.object(details) ? JSON.stringify(details) : details
    }`;
  };
}
