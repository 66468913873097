import UserRole from '../UserRole';

type RoleToRoleList = {
  [K in UserRole]?: UserRole[];
};

const roleToRoleList: RoleToRoleList = {
  [UserRole.DropSuperAdmin]: [
    UserRole.DropSuperAdmin,
    UserRole.MachineSuperAdmin,
    UserRole.MachineAdmin,
    UserRole.User
  ],
  [UserRole.MachineSuperAdmin]: [UserRole.MachineSuperAdmin, UserRole.MachineAdmin, UserRole.User]
};

/**
 * Gets the list of selectable roles based on the current user's role.
 *
 * @returns A list of selectable roles for the given role, or undefined if none exist.
 */
const selectableUserRoles = (currentRole?: UserRole): UserRole[] | undefined => {
  if (!currentRole) return undefined;
  return roleToRoleList[currentRole];
};

export default selectableUserRoles;
