import RequestMethod from './RequestMethod';
import axios, { AxiosRequestConfig } from 'axios';
import RestApiError from './RestApiError';

export default class ApiService {
  constructor(public host: string) {}

  public request = async <T = any>(
    path: string,
    method: RequestMethod = 'GET',
    params: URLSearchParams = new URLSearchParams(),
    body: object = {}
  ): Promise<T> => {
    try {
      const config: AxiosRequestConfig = {
        method,
        url: `${this.host}/${path}`,
        params,
        data: body
      };
      const response = (await axios.request<T>(config)).data;
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error;
        const errorMessage = axiosError.response?.data || axiosError.message || 'Unknown error';
        const status = axiosError.response?.status || 'Network Error';
        const apiError = new RestApiError(status, errorMessage, path);
        console.error(`Error requesting ${path}`, apiError);
        throw apiError;
      } else {
        console.error(`Unexpected error occurred processing a request from ${path}`, error);
        throw new Error(`Unexpected error occurred processing a request from ${path}`);
      }
    }
  };
}
