import CompanyService from '../../services/Company/CompanyService';
import ICompany from '../../services/Company/ICompany';
import EntityList from '../EntityManagement/EntityList/EntityList';
import EntityType from '../EntityManagement/EntityType';
import CompaniesTableConfig from './CompaniesTable/CompaniesTableConfig';
import CompanyColumnKeys from './CompaniesTable/CompanyColumnKeys';
import CompanyCreate from './CompanyCreate/CompanyCreate';

interface ICompaniesList {
  organizationId?: string;
  operatorId?: string;
  heading?: string;
}

const CompaniesList: React.FC<ICompaniesList> = ({ organizationId, heading, operatorId }) => {
  return (
    <EntityList<ICompany, CompanyColumnKeys>
      entityService={new CompanyService()}
      entityType={EntityType.Company}
      heading={heading}
      dataColumnConfig={CompaniesTableConfig()}
      createComponent={CompanyCreate}
      sortKey='name'
      filterOptions={{
        organizationId,
        operatorId
      }}
      rowLinkConfig={{
        path: 'companies',
        suffix: 'edit',
        key: '_id'
      }}
    />
  );
};

export default CompaniesList;
