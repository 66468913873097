import React from 'react';
import Inventory from '../../services/Kiosk/IInventory';
import KioskType from '../../common/Kiosk/KioskType';

interface KioskAdditivesInventoryTableProps {
  inventory?: Inventory;
  kioskType?: string;
}

const KioskAdditiveInventoryTable: React.FC<KioskAdditivesInventoryTableProps> = ({ inventory, kioskType }) => {
  const rows: { label: string; value?: number; shouldDisplay?: boolean }[] = [
    { label: 'CO2 PSI', value: inventory?.CO2PSI, shouldDisplay: kioskType === KioskType.Refill },
    { label: 'Lemonade V3', value: inventory?.lemonadeV3 },
    { label: 'Blackberry', value: inventory?.blackberry },
    { label: 'Mint Mojito', value: inventory?.mintMojito },
    { label: 'Orange + Vitamin C', value: inventory?.orange },
    { label: 'Tropical Energy', value: inventory?.tropicalEnergy },
    { label: 'Passion Orange Guava', value: inventory?.passionOrangeGuava },
    { label: 'Peach Tea', value: inventory?.peachTea },
    { label: 'Pomegranate Acai', value: inventory?.pomegranateAcai },
    { label: 'Raspberry Lychee', value: inventory?.raspberryLychee },
    { label: 'Strawberry Kiwi', value: inventory?.strawberryKiwi },
    { label: 'Tea + Lemonade', value: inventory?.teaPlusLemonade },
    { label: 'Yuzu Ginger', value: inventory?.yuzuGinger },
    { label: 'Sanitizer', value: inventory?.sanitizer }
  ];

  return (
    <table style={{ tableLayout: 'fixed' }} className='kiosk-additives-inventory-table'>
      <tbody>
        {rows
          .filter((row) => row.shouldDisplay === undefined || row.shouldDisplay)
          .map((row) => (
            <tr key={row.label}>
              <th className='left-align inventory-summary-header'>{row.label}</th>
              <td className='center-align'>{row.value?.toFixed(0) || '-'}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default KioskAdditiveInventoryTable;
