import { MenuItem, TextField } from '@material-ui/core';
import stringWithSpacesUppercase from '../../../utils/stringWithSpacesUppercase';

interface IEnumSelectInput<T extends string | number> {
  value: T;
  onChange: (value: T) => void;
  enumValues: Record<string, T>;
  label: string;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: string;
}

const EnumSelectInput = <T extends string | number>({
  value,
  onChange,
  enumValues,
  label,
  fullWidth = true,
  required = true,
  disabled,
  error
}: IEnumSelectInput<T>) => {
  return (
    <TextField
      select
      size='small'
      variant='outlined'
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value as T)}
      style={{ margin: '10px 0' }}
      disabled={disabled}
      fullWidth={fullWidth}
      required={required}
      error={!!error}
      helperText={error || ''}
    >
      {Object.keys(enumValues).map((key) => (
        <MenuItem key={key} value={enumValues[key]}>
          {stringWithSpacesUppercase(key)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default EnumSelectInput;
