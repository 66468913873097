import { useState, useEffect, useCallback } from 'react';
import CompanyService from './CompanyService';
import UserRole from '../../common/UserRole';
import ICompany from './ICompany';
import { useAppSelector } from '../../hooks/storeHooks';

interface IUseCompanies {
  organizationId?: string;
  operatorId?: string;
}

const sortCompanies = (companies: ICompany[]): ICompany[] => {
  return companies.sort((a, b) => a.name.localeCompare(b.name));
};

const useCompanies = ({
  organizationId,
  operatorId
}: IUseCompanies): {
  companies: ICompany[];
  loading: boolean;
  error: string | null;
  fetchNewCompanies: () => Promise<void>;
} => {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const user = useAppSelector((store) => store.auth?.user);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchCompanies = useCallback(async () => {
    if (user?.role !== UserRole.DropSuperAdmin) {
      return;
    }
    setLoading(true);
    try {
      const data = await new CompanyService().getAllEntities({ operatorId, organizationId });
      setCompanies(sortCompanies(data));
    } catch (err) {
      setError('Failed to load companies');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [operatorId, organizationId, user?.role]);

  const fetchNewCompanies = useCallback(async () => {
    if (user?.role !== UserRole.DropSuperAdmin) {
      return;
    }
    setLoading(true);
    try {
      const data = await new CompanyService().getAllEntities({ operatorId, organizationId });
      setCompanies((prevCompanies) => {
        const newCompanies = data.filter((newCompany) => !prevCompanies.some((comp) => comp._id === newCompany._id));
        const mergedCompanies = [...prevCompanies, ...newCompanies];
        return sortCompanies(mergedCompanies);
      });
    } catch (err) {
      setError('Failed to fetch new companies');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [operatorId, organizationId, user?.role]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  return { companies, loading, error, fetchNewCompanies };
};

export default useCompanies;
