import { useState, useCallback, useEffect } from 'react';
import EntityDataAccessService from '../../../services/GenericEntity/EntityDataAccessService';
import { useEntityData } from '../../../services/GenericEntity/useEntityData';
import EntityType from '../EntityType';
import { FieldType } from '../../../components/Form/FormBuilder/FieldConfig';
import ObjectUtility from '../../../utils/ObjectUtility/ObjectUtility';

interface IUseEntityStateOptions<T> {
  service: EntityDataAccessService<T>;
  entityId?: string;
  entityType: EntityType;
  onBeforeStateUpdate?: (key: keyof T, value: any, entityState: T) => T;
}

export function useEntityState<T>({ service, entityId, entityType, onBeforeStateUpdate }: IUseEntityStateOptions<T>) {
  const entityData = useEntityData(service, entityId);
  const { entity, updateEntity, loading, error, deleteEntity, createEntity } = entityData;
  const [formState, setFormState] = useState<T | null>(entity);
  const [currentTab, setCurrentTab] = useState('general');
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    if (entity) {
      setFormState(entity);
      setUnsavedChanges(false);
    }
  }, [entity]);

  const initializeEntity = (initialEntity: Partial<T>) => {
    setFormState(initialEntity as T);
    setUnsavedChanges(true);
  };

  const switchTab = (tabKey: string) => setCurrentTab(tabKey);

  const handleFieldChange = (type: FieldType, key: keyof T | string, value: any) => {
    if (!formState) return;

    const updatedState = { ...formState };

    if (type === 'checkbox-list') {
      const existingValue = ObjectUtility.getValueByDotNotatedPath<T, unknown[]>(updatedState, key as string) || [];
      if (!Array.isArray(existingValue)) {
        console.warn(`Expected an array at ${String(key)}, but got:`, existingValue);
        return;
      }

      const newValue = ObjectUtility.toggleArrayValue(existingValue, value);
      ObjectUtility.setValueByDotNotatedPath(updatedState, key as string, newValue);
    } else {
      ObjectUtility.setValueByDotNotatedPath(updatedState, key as string, value);
    }

    const modifiedState = onBeforeStateUpdate ? onBeforeStateUpdate(key as keyof T, value, updatedState) : updatedState;

    setFormState(modifiedState);
    setUnsavedChanges(true);
  };

  const saveChanges = useCallback(async () => {
    if (formState) {
      await updateEntity(formState);
      setUnsavedChanges(false);
    }
  }, [formState, updateEntity]);

  const createNew = useCallback(
    async (redirectOnSubmitPath?: string) => {
      if (!formState) throw new Error('Cannot create empty entity');
      return createEntity(formState, redirectOnSubmitPath);
    },
    [createEntity, formState]
  );

  return {
    state: {
      entity,
      formState,
      loading,
      error,
      currentTab,
      unsavedChanges
    },
    switchTab,
    handleFieldChange,
    saveChanges,
    deleteEntity,
    initializeEntity,
    createNew,
    entityType
  };
}
