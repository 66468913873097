import React, { useRef } from 'react';
import EntityAction from '../../../../services/GenericEntity/EntityAction';
import styles from './EntityListActions.module.css';
import ButtonRoundedPrimary from '../../../../components/Button/ButtonRounded/ButtonRoundedPrimary';
import { AddCircleOutline } from '@material-ui/icons';
import stringWithSpacesUppercase from '../../../../utils/stringWithSpacesUppercase';
import EntityType from '../../EntityType';
import kebabToTitleCase from '../../../../utils/kebabToTitleCase';

interface IEntityListActionsProps<T> {
  /**
   * Array of actions to enable for the list.
   */
  actions: EntityAction[];

  /**
   * Keys of the selected rows.
   */
  selectedKeys: Array<keyof T>;

  /**
   * Callback triggered when an action is clicked.
   *
   * @param action - The action that was clicked.
   * @param selectedKeys - The keys of the selected rows.
   */
  onActionClick: (action: EntityAction, selectedKeys: Array<keyof T>) => void;
  entityType: EntityType;
}

const EntityListActions = <T extends unknown>({
  actions,
  selectedKeys,
  onActionClick,
  entityType
}: IEntityListActionsProps<T>) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const action = event.target.value as EntityAction;
    if (action) {
      onActionClick(action, selectedKeys);
      if (selectRef.current) {
        selectRef.current.value = '';
      }
    }
  };

  const nonCreateActions = actions.filter((a) => a !== EntityAction.Create);

  return (
    <div className={styles.EntityListActions}>
      {actions.includes(EntityAction.Create) && (
        <ButtonRoundedPrimary
          label={`Create ${stringWithSpacesUppercase(entityType)}`}
          disabled={false}
          onClick={() => onActionClick(EntityAction.Create, [])}
          icon={<AddCircleOutline />}
        />
      )}
      {nonCreateActions.length > 0 && (
        <select ref={selectRef} onChange={handleSelectChange} defaultValue=''>
          <option value='' disabled>
            Actions
          </option>
          {nonCreateActions.map((action) => (
            <option key={action} value={action} disabled={selectedKeys.length === 0}>
              {kebabToTitleCase(action)}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default EntityListActions;
