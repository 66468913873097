import React from 'react';
import Inventory from '../../services/Kiosk/IInventory';

interface KioskBottleInventoryTableProps {
  inventory?: Inventory;
}

const KioskBottleInventoryTable: React.FC<KioskBottleInventoryTableProps> = ({ inventory }) => {
  const rows = [
    { label: 'Active Tower', value: inventory?.towerSelected },
    { label: 'Tower 1', value: inventory?.towerOneRemaining },
    { label: 'Tower 2', value: inventory?.towerTwoRemaining },
    { label: 'Tower 3', value: inventory?.towerThreeRemaining }
  ];

  return (
    <table style={{ tableLayout: 'fixed' }} className='kiosk-bottle-inventory-table'>
      <tbody>
        {rows.map((row) => (
          <tr key={row.label}>
            <th className='left-align inventory-summary-header'>{row.label}</th>
            <td className='center-align'>{row.value ?? '-'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default KioskBottleInventoryTable;
