import { FieldConfig } from '../../../components/Form/FormBuilder/FieldConfig';
import FieldConfigUtility from '../../../components/Form/FormBuilder/FieldConfigUtility';
import ICompany from '../../../services/Company/ICompany';
import IKiosk from '../../../services/Kiosk/IKiosk';
import IOperator from '../../../services/Operators/IOperator';
import IOrganization from '../../../services/Organizations/IOrganization';

export const KioskFieldConfig = ({
  operators,
  organizations,
  companies,
  excludeKeys
}: {
  organizations?: IOrganization[];
  operators?: IOperator[];
  companies?: ICompany[];
  excludeKeys?: Array<keyof IKiosk>;
}): FieldConfig<IKiosk>[] => {
  const fields: FieldConfig<IKiosk>[] = [
    { key: 'kiosk_ID', required: true, type: 'string' },
    { key: 'alias', required: true, type: 'string' },
    { key: 'location', required: false, type: 'string' },
    { key: 'locationAlias', required: false, type: 'string' }
  ];
  if (operators && operators.length > 0)
    fields.push({
      key: 'operator',
      required: false,
      type: 'select',
      options: FieldConfigUtility.createFieldOptions<IOperator>('_id', 'name', operators)
    });
  if (organizations && organizations.length > 0)
    fields.push({
      key: 'organization',
      required: false,
      type: 'select',
      options: FieldConfigUtility.createFieldOptions<IOrganization>('_id', 'name', organizations)
    });
  if (companies && companies.length > 0)
    fields.push({
      key: 'company',
      required: false,
      type: 'select',
      options: FieldConfigUtility.createFieldOptions<ICompany>('_id', 'name', companies)
    });

  if (excludeKeys) {
    return fields.filter((field) => !excludeKeys.includes(field.key as keyof IKiosk));
  }

  return fields;
};
