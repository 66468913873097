import React from 'react';
import EntityEditScreen from '../../EntityManagement/EntityEdit/EntityEditScreen';
import OrganizationService from '../../../services/Organizations/OrganizationService';
import EntityForm from '../../EntityManagement/EntityForm/EntityForm';
import { OrganizationGeneralFieldConfig } from './OrganizationFieldConfig';
import UsersList from '../../Users/UsersList';
import useOperatorData from '../../../services/Operators/useOperatorData';
import IOperator from '../../../services/Operators/IOperator';
import EntityType from '../../EntityManagement/EntityType';
import CompaniesList from '../../Companies/CompaniesList';
import KiosksList from '../../Kiosk/KiosksList/KiosksList';

const OrganizationEditTabsConfig = (organizationId: string, operatorDocs: IOperator[]) => [
  {
    label: 'General',
    key: 'general',
    component: <EntityForm mode='edit' fieldConfig={OrganizationGeneralFieldConfig(operatorDocs)} />
  },
  {
    label: 'Companies',
    key: 'companies',
    component: <CompaniesList heading='Companies' organizationId={organizationId} />
  },
  { label: 'Users', key: 'users', component: <UsersList organizationId={organizationId} heading='Users' /> },
  {
    label: 'Kiosks',
    key: 'kiosks',
    component: <KiosksList organizationId={organizationId} excludeKeys={['organization']} heading='Kiosks' />
  }
];

interface IOrganizationEdit {
  organizationId: string;
}

const OrganizationEdit: React.FC<IOrganizationEdit> = ({ organizationId }) => {
  const { operators } = useOperatorData();

  return (
    <EntityEditScreen
      entityId={organizationId}
      tabConfig={OrganizationEditTabsConfig(organizationId, operators)}
      service={new OrganizationService()}
      entityType={EntityType.Organization}
      headingKey={'name'}
      displayBackButton
    />
  );
};

export default OrganizationEdit;
