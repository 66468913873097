import IKiosk from '../../../services/Kiosk/IKiosk';
import KioskDataAccessService from '../../../services/Kiosk/KioskDataAccessService';
import EntityList from '../../EntityManagement/EntityList/EntityList';
import EntityType from '../../EntityManagement/EntityType';
import KioskCreate from '../KioskCreate/KioskCreate';
import KiosksColumnKeys from './KiosksTable/KiosksColumnKeys';
import KiosksTableConfig from './KiosksTable/KiosksTableConfig';

interface IKiosksList {
  operatorId?: string;
  heading?: string;
  companyId?: string;
  organizationId?: string;
  excludeKeys?: KiosksColumnKeys[];
}

const KiosksList: React.FC<IKiosksList> = ({ operatorId, companyId, organizationId, excludeKeys }) => {
  return (
    <EntityList<IKiosk, KiosksColumnKeys>
      entityService={new KioskDataAccessService()}
      entityType={EntityType.Kiosk}
      heading='Kiosks'
      dataColumnConfig={KiosksTableConfig({ excludeKeys })}
      createComponent={KioskCreate}
      sortKey='kiosk_ID'
      filterOptions={{
        operatorId,
        organizationId,
        companyId
      }}
      rowLinkConfig={{
        key: 'kiosk_ID',
        path: 'kiosk'
      }}
    />
  );
};

export default KiosksList;
