import React from 'react';
import { useContext } from 'react';
import { KioskSettingsContext } from '../KioskSettingsContext';
import { BottleInventorySettings, InventoryAlarmsConfig } from './InventoryControlSettings';
import { InventoryAlarmFieldConfig, BottleInventoryControlFieldConfig } from '../../Fields/KioskSettingsFieldConfig';
import FieldRenderer from '../../../../../components/Form/FormBuilder/FieldRenderer';
import KioskEditStrings from '../../KioskEditStrings';
import KioskType from '../../../../../common/Kiosk/KioskType';
import formatFieldName from '../../../../../utils/formatFieldName';
import { FieldKeys } from '../../../../../components/Form/FormBuilder/FieldConfig';

const BottleInventoryControl: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);
  const inventoryControl = kioskSettings?.state.settings?.inventoryControl;
  const bottleSettings = inventoryControl?.bottles;
  const alarmThresholdChangeStep: number = 10;

  const updateBottleInventoryControl = (key: FieldKeys<BottleInventorySettings>, value: any) => {
    if (!bottleSettings) return;

    kioskSettings?.handleInventoryControlChange('bottles', {
      ...bottleSettings,
      [key]: value
    });
  };

  const updateAlarmThreshold = (alarmType: FieldKeys<InventoryAlarmsConfig>, value: number) => {
    if (!bottleSettings?.alarms || value < 0) return;

    const updatedAlarms = {
      ...bottleSettings.alarms,
      [alarmType]: { threshold: value }
    };

    kioskSettings?.handleInventoryControlChange('bottles', {
      ...bottleSettings,
      alarms: updatedAlarms
    });
  };

  const kioskType = kioskSettings?.state?.settings?.installInfo?.kioskType;

  const fields = BottleInventoryControlFieldConfig.filter(
    (field) => field.kioskTypes.length === 0 || (kioskType && field.kioskTypes.includes(kioskType))
  );

  const alarmFields = InventoryAlarmFieldConfig.filter(
    (field) => field.kioskTypes.length === 0 || (kioskType && field.kioskTypes.includes(kioskType))
  );

  if (fields.length + alarmFields.length === 0 || kioskType !== KioskType.Duo || !bottleSettings) return null;

  return (
    <>
      <h5 style={{ fontWeight: '600', fontSize: '14px' }}>{KioskEditStrings.BottleInventoryControlLabel}</h5>
      <div className='row' style={{ width: '100%', marginBottom: 0 }}>
        {fields.map((field) => (
          <div key={field.key} className='col s12 m8 l8'>
            <FieldRenderer
              field={field}
              value={bottleSettings?.[field.key] || ''}
              onChange={updateBottleInventoryControl}
              disabled={!kioskSettings?.deviceStatus.ready}
            />
          </div>
        ))}
      </div>
      <div className='row' style={{ width: '100%', marginBottom: 0 }}>
        {alarmFields.map((field) => (
          <div key={String(field.key)} className='col s12 m4 l4'>
            <FieldRenderer
              field={field}
              value={
                bottleSettings?.alarms?.[field.key]?.threshold === undefined
                  ? ''
                  : bottleSettings?.alarms?.[field.key]?.threshold
              }
              onChange={(key, val) => updateAlarmThreshold(key, +val)}
              disabled={!kioskSettings?.deviceStatus.ready}
              labelOverride={formatFieldName(`${field.key} Bottle Count ${KioskEditStrings.AlarmThresholdLabel}`)}
              inputProps={{ step: String(alarmThresholdChangeStep) }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default BottleInventoryControl;
