import { useState, useEffect } from 'react';
import OperatorService from './OperatorService';
import IOperator from './IOperator';

const useOperatorData = () => {
  const [operators, setOperators] = useState<IOperator[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const data = await new OperatorService().getAllEntities();
        setOperators(data);
      } catch (err) {
        setError('Failed to load operators');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchOperators();
  }, []);

  return { operators, loading, error };
};

export default useOperatorData;