import React from 'react';
import styles from './ButtonRounded.module.css';

interface IButtonRounded {
  background?: {
    disabled: string;
    enabled: string;
  };
  border?: {
    width: number;
    color: string;
  };
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  label: string;
  font?: {
    color?: string;
  };
  icon?: JSX.Element;
  type?: 'button' | 'reset' | 'submit';
}

const ButtonRounded: React.FC<IButtonRounded> = ({
  background,
  border = { width: 0, color: 'transparent' },
  onClick,
  disabled = false,
  label,
  font,
  icon,
  type = 'button'
}) => {
  const buttonStyles: React.CSSProperties = {
    border: `${border.width}px solid ${border.color}`
  };

  if (background) buttonStyles.backgroundColor = disabled ? background.disabled : background.enabled;

  if (font?.color) buttonStyles.color = font.color;

  return (
    <button
      className={styles.ButtonRounded}
      style={buttonStyles}
      onClick={onClick}
      disabled={disabled}
      type={type}
      data-testid='ButtonRounded'
    >
      {label} {icon}
    </button>
  );
};

export default ButtonRounded;
