import EntityCreate from '../../EntityManagement/EntityCreate/EntityCreate';
import OrganizationService from '../../../services/Organizations/OrganizationService';
import IOrganization from '../../../services/Organizations/IOrganization';
import EntityType from '../../EntityManagement/EntityType';
import GenericEntity from '../../../services/GenericEntity/GenericEntity';
import IEntityCreateComponent from '../../EntityManagement/EntityCreate/IEntityCreateComponent';
import { useEntityManagementContext } from '../../EntityManagement/context/useEntityManagementContext';
import buildInitialEntity from '../../EntityManagement/EntityCreate/buildInitialEntity';
import { useOrganizationFormConfig } from '../useOrganizationFormConfig';

const OrganizationCreate = <ParentType extends GenericEntity>({ onSuccess }: IEntityCreateComponent<IOrganization>) => {
  const parentEntity = useEntityManagementContext<ParentType>();
  const initialEntity = buildInitialEntity<IOrganization, ParentType>(parentEntity, {
    [EntityType.Operator]: { targetField: 'operator', sourceField: '_id' }
  });
  const formConfig = useOrganizationFormConfig();

  return (
    <EntityCreate<IOrganization>
      service={new OrganizationService()}
      formConfig={formConfig}
      initialEntity={initialEntity}
      onSuccess={onSuccess}
      entityType={EntityType.Organization}
    />
  );
};

export default OrganizationCreate;
