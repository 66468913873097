import kebabToTitleCase from '../../../../utils/kebabToTitleCase';
import UserRole from '../../../../common/UserRole';

interface IUserRoleDisplayOnly {
  userRole?: UserRole;
  isCurrentUser: boolean;
}

const UserRoleDisplayOnly: React.FC<IUserRoleDisplayOnly> = ({ userRole, isCurrentUser }) => {
  return (
    <>
      <strong>{isCurrentUser ? 'Your ' : ''}Role:</strong> {kebabToTitleCase(userRole ?? 'unknown')}
    </>
  );
};

export default UserRoleDisplayOnly;
