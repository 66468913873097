import IOperator from '../../services/Operators/IOperator';
import OperatorService from '../../services/Operators/OperatorService';
import EntityList from '../EntityManagement/EntityList/EntityList';
import EntityType from '../EntityManagement/EntityType';
import OperatorCreate from './OperatorCreate/OperatorCreate';
import OperatorColumnKeys from './OperatorsTable/OperatorColumnKeys';
import OperatorsTableConfig from './OperatorsTable/OperatorsTableConfig';

interface IOperatorsList {
  heading?: string;
}

const OperatorsList: React.FC<IOperatorsList> = ({ heading }) => {
  return (
    <EntityList<IOperator, OperatorColumnKeys>
      entityService={new OperatorService()}
      entityType={EntityType.Operator}
      heading={heading}
      dataColumnConfig={OperatorsTableConfig()}
      createComponent={OperatorCreate}
      sortKey='name'
      filterOptions={{}}
      rowLinkConfig={{
        path: 'operators',
        suffix: 'edit',
        key: '_id'
      }}
    />
  );
};

export default OperatorsList;
