import { useAppSelector } from '../../../hooks/storeHooks';
import useOperatorData from '../../../services/Operators/useOperatorData';
import IUser from '../../../services/User/IUser';
import UserRole from '../../../common/UserRole';
import { KioskFieldConfig } from './KioskFieldConfig';
import useCompanies from '../../../services/Company/useCompany';
import useOrganizationData from '../../../services/Organizations/useOrganizationData';
import IKiosk from '../../../services/Kiosk/IKiosk';

export const useKioskFormConfig = ({
  operatorId,
  excludeKeys
}: {
  operatorId?: string;
  excludeKeys?: Array<keyof IKiosk>;
}) => {
  const user = useAppSelector<IUser>((store) => store.auth?.user);
  const { operators } = useOperatorData();
  const { organizations } = useOrganizationData({
    operatorId
  });
  const { companies } = useCompanies({});

  const formConfig = KioskFieldConfig({
    operators: user.role === UserRole.DropSuperAdmin ? operators : [],
    organizations: user.role === UserRole.DropSuperAdmin ? organizations : [],
    companies: user.role === UserRole.DropSuperAdmin ? companies : [],
    excludeKeys
  });

  return formConfig;
};
