import KioskCommandSequence from '../../common/Kiosk/KioskCommandSequence';
import KioskSettings, { Features } from '../../common/Kiosk/KioskSettings';
import CommandCode from '../../common/Peripherals/FirmwareCommunication/Messaging/CommandCode';
import PeripheralIdentifier from '../../common/Peripherals/PeripheralIdentifier';
import ConfigService from '../ConfigService';
import ApiService from '../HttpClient/ApiService';
import MediaType from '../Media/MediaType';
import CameraSnapshotResponse from './CameraSnapshotResponse';
import CommandSequenceResponse from './CommandSequenceResponse';
import FeatureToggleResponse from './FeatureToggleResponse';
import IKioskAccessToken from './IKioskAccessToken';
import IKioskDetails from './IKioskDetails';
import KioskCommand from './KioskCommand';
import KioskSettingsUpdateResponse from './KioskSettingsUpdateResponse';
import KioskStatusReport from './KioskStatusReport';
import KioskStatus from './KioskStatusReport';
import PeripheralCommandResponse from './PeripheralCommandResponse';
import ResetResponse from './ResetResponse';
import ScreenshotResponse from './ScreenshotResponse';
import SoftwareUpdateResponse from './SoftwareUpdateResponse';

export default class KioskService {
  apiService: ApiService;

  constructor(public kioskId?: string) {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getDeviceStatus = async (): Promise<KioskStatusReport> => {
    if (!this.kioskId) throw new Error(`no kioskId defined in KioskService`);
    const params = new URLSearchParams();
    params.append('kioskId', this.kioskId);
    const deviceStatus = await this.apiService.request('kiosk/status', 'GET', params);
    return deviceStatus as unknown as KioskStatus;
  };

  sendToggleFeatureCommand = async (
    command: KioskCommand,
    enable: boolean,
    uuid: string,
    feature?: keyof Features
  ): Promise<FeatureToggleResponse> => {
    const body = {
      kioskId: this.kioskId,
      command,
      enable,
      uuid,
      feature,
    };
    const commandResponse = await this.apiService.request('kiosk/toggle-feature', 'PUT', undefined, body);
    return commandResponse as unknown as FeatureToggleResponse;
  };

  sendResetCommand = async (): Promise<ResetResponse> => {
    if (!this.kioskId) throw new Error(`no kioskId defined in KioskService`);
    const requestParams = new URLSearchParams();
    requestParams.append('kioskId', this.kioskId);
    requestParams.append('command', KioskCommand.Reset);
    const commandResponse = await this.apiService.request('kiosk/reset', 'GET', requestParams);
    return commandResponse as unknown as ResetResponse;
  };

  getAllDetails = async (): Promise<IKioskDetails> => {
    if (!this.kioskId) throw new Error(`no kioskId defined in KioskService`);
    const requestParams = new URLSearchParams();
    requestParams.append('kioskId', this.kioskId);
    const details = await this.apiService.request('kiosk/kiosk', 'GET', requestParams);
    return details as unknown as IKioskDetails;
  };

  sendSoftwareUpdateRequest = async (ignoreMaintenance: boolean, version?: string): Promise<SoftwareUpdateResponse> => {
    const body = {
      kioskId: this.kioskId,
      ignoreMaintenance,
      version,
    };
    const commandResponse = await this.apiService.request('kiosk/software/update', 'PUT', undefined, body);
    return commandResponse as unknown as SoftwareUpdateResponse;
  };

  sendScreenshotRequest = async (): Promise<ScreenshotResponse> => {
    if (!this.kioskId) throw new Error(`no kioskId defined in KioskService`);
    const requestParams = new URLSearchParams();
    requestParams.append('kioskId', this.kioskId);
    requestParams.append('mediaType', MediaType.Screenshot);
    const screenshotResponse = await this.apiService.request('kiosk/media', 'GET', requestParams);
    return screenshotResponse as unknown as ScreenshotResponse;
  };


  sendCameraSnapshotRequest = async (): Promise<CameraSnapshotResponse> => {
    if (!this.kioskId) throw new Error(`no kioskId defined in KioskService`);
    const requestParams = new URLSearchParams();
    requestParams.append('kioskId', this.kioskId);
    requestParams.append('mediaType', MediaType.CameraSnapshot);
    const snapshotResponse = await this.apiService.request('kiosk/media', 'GET', requestParams);
    return snapshotResponse as unknown as CameraSnapshotResponse;
  };

  generateAccessToken = async () => {
    if (!this.kioskId) throw new Error(`no kioskId defined in KioskService`);
    const body = {
      kioskId: this.kioskId,
    };
    const commandResponse = await this.apiService.request(`kiosk/access-token`, 'POST', undefined, body);
    return commandResponse as unknown as IKioskAccessToken;
  };

  sendPeripheralCommand = async (
    command: CommandCode,
    peripheralIdentifier: PeripheralIdentifier,
    args: number[][]
  ): Promise<PeripheralCommandResponse> => {
    const body = {
      kioskId: this.kioskId,
      command,
      peripheralIdentifier,
      args,
    };
    const commandResponse = await this.apiService.request('kiosk/peripheral-command', 'PUT', undefined, body);
    return commandResponse as unknown as PeripheralCommandResponse;
  };

  getKioskSettings = async (): Promise<KioskSettings> => {
    if (!this.kioskId) throw new Error(`no kioskId defined in KioskService`);
    const requestParams = new URLSearchParams();
    requestParams.append('kioskId', this.kioskId);
    const settingsResponse = await this.apiService.request(`kiosk/${this.kioskId}/settings`, 'GET', requestParams);
    return settingsResponse as unknown as KioskSettings;
  };

  updateKioskSettings = async (settings: KioskSettings): Promise<KioskSettingsUpdateResponse> => {
    if (!this.kioskId) throw new Error(`no kioskId defined in KioskService`);
    const body = {
      kioskId: this.kioskId,
      settings,
    };
    const updateResponse = await this.apiService.request(`kiosk/${this.kioskId}/settings`, 'PUT', undefined, body);
    return updateResponse as unknown as KioskSettingsUpdateResponse;
  };

  runCommandSequence = async (
    sequence: KioskCommandSequence,
  ): Promise<CommandSequenceResponse> => {
    const body = {
      kioskId: this.kioskId,
      sequence
    };
    const commandResponse = await this.apiService.request(`kiosk/${this.kioskId}/command-sequence`, 'PUT', undefined, body);
    return commandResponse as unknown as CommandSequenceResponse;
  };
}
