import ThemeConfig from '../../../theme/ThemeConfig';
import rgbaToRgbString from '../../../utils/rgbaToRgbString';
import ButtonRounded from './ButtonRounded';

interface IButtonRoundedPrimary {
  disabled: boolean;
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "reset" | "submit"
  icon?: JSX.Element;
}

const ButtonRoundedPrimary: React.FC<IButtonRoundedPrimary> = ({ disabled, label, onClick, type, icon }) => {
  return (
    <ButtonRounded
      disabled={disabled}
      onClick={onClick}
      label={label}
      background={{
        disabled: rgbaToRgbString(ThemeConfig.colors.secondary),
        enabled: rgbaToRgbString(ThemeConfig.colors.tertiary)
      }}
      font={{
        color: rgbaToRgbString(ThemeConfig.colors.white)
      }}
      type={type}
      icon={icon}
    />
  );
};

export default ButtonRoundedPrimary;
