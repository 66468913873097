import EntityCreate from '../../EntityManagement/EntityCreate/EntityCreate';
import EntityType from '../../EntityManagement/EntityType';
import GenericEntity from '../../../services/GenericEntity/GenericEntity';
import IEntityCreateComponent from '../../EntityManagement/EntityCreate/IEntityCreateComponent';
import { useEntityManagementContext } from '../../EntityManagement/context/useEntityManagementContext';
import buildInitialEntity from '../../EntityManagement/EntityCreate/buildInitialEntity';
import { useKioskFormConfig } from '../KioskForm/useKioskFormConfig';
import IKiosk from '../../../services/Kiosk/IKiosk';
import KioskDataAccessService from '../../../services/Kiosk/KioskDataAccessService';

const KioskCreate = <ParentType extends GenericEntity>({ onSuccess }: IEntityCreateComponent<IKiosk>) => {
  const parentEntity = useEntityManagementContext<ParentType>();
  const initialEntity = buildInitialEntity<IKiosk, ParentType>(parentEntity, {
    [EntityType.Operator]: { targetField: 'operator', sourceField: '_id' },
    [EntityType.Organization]: { targetField: 'organization', sourceField: '_id' },
    [EntityType.Company]: { targetField: 'company', sourceField: '_id' }
  });
  const formConfig = useKioskFormConfig({});

  return (
    <EntityCreate<IKiosk>
      service={new KioskDataAccessService()}
      formConfig={formConfig}
      initialEntity={initialEntity}
      onSuccess={onSuccess}
      entityType={EntityType.Kiosk}
    />
  );
};

export default KioskCreate;
