/**
 * Prevents fields retaining values from unrelated entities.
 *
 */
const useResetEntityRelationships = <T,>() => {
  return (key: keyof T, _value: any, entityState: T): T => {
    // If the Operator an entity is assigned to changes, all existing relationships need resetting
    if (key === 'operator') {
      return { ...entityState, organization: null, company: null } as T;
    }

    if (key === 'organization') {
      return { ...entityState, company: null } as T;
    }

    return entityState;
  };
};

export default useResetEntityRelationships;
