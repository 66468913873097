import is from 'check-types';
import RGBAColor from '../common/RGBAColor';

/** Fallback color to use in case an undefined or invalid RGBAColor provided */
const fallbackColor: RGBAColor = [128, 128, 128, 1];

/**
 * Converts an RGBA color array to an RGB string
 * @returns A string representing the RGB value
 */
export default function rgbaToRgbString(rgbaColor: RGBAColor): string {
  let rgbaColorArray: RGBAColor = rgbaColor;
  if (!is.array(rgbaColor) || !rgbaColor.every((value) => is.number(value)) || rgbaColor.length !== 4) {
    const err = new Error(`Invalid RGBAColor, expected array of 4 numbers but received: ${rgbaColor}`);
    console.error(err);
    rgbaColorArray = fallbackColor;
  }

  const [r, g, b, a] = rgbaColorArray;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}
