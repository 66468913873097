import { Component } from 'react';
import 'materialize-css/dist/js/materialize.min.js';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import { Provider } from 'react-redux';
import store from './store';
import FloatingActionButton from './components/layout/FloatingActionButton';
import Login from './components/auth/Login';
import PrivateRoute from './components/private-route/PrivateRoute';
import Overview from './screens/Overview/Overview';
import 'materialize-css/dist/css/materialize.min.css';
import './App.css';
import OrdersList from './screens/Orders/OrdersList';
import NavDrawer from './components/drawer/NavDrawer';
import InventoryList from './screens/InventoryList/InventoryList';
import Kiosk from './screens/Kiosk/Kiosk';
import ErrorBoundary from './components/error/ErrorBoundary';
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import NotFound from './screens/Error/NotFound';
import UsersList from './screens/Users/UsersList';
import MediaList from './screens/Media/MediaList';
import KioskEdit from './screens/Kiosk/KioskEdit/KioskEdit';
import KiosksHealth from './screens/Reports/KiosksHealth/KiosksHealth';
import IncidentReports from './screens/Reports/IncidentReport/IncidentReportList/IncidentReports';
import IncidentReport from './screens/Reports/IncidentReport/IncidentReportSingle/IncidentReport';
import OperatorsList from './screens/Operators/OperatorsList';
import OrganizationsList from './screens/Organizations/OrganizationsList';
import CompaniesList from './screens/Companies/CompaniesList';
import CompanyEdit from './screens/Companies/CompanyEdit/CompanyEdit';
import OrganizationEdit from './screens/Organizations/OrganizationEdit/OrganizationEdit';
import OperatorEdit from './screens/Operators/OperatorEdit/OperatorEdit';
import KiosksList from './screens/Kiosk/KiosksList/KiosksList';
import UserEdit from './screens/Users/UserEdit/UserEdit';

Sentry.init({
  dsn: 'https://6071d902eb2f4d4caf7584eac955ed1e@o445416.ingest.sentry.io/5421785',
  release: process?.env ? 'dropwater-dashboard@' + process.env.npm_package_version : 'test',
  integrations: [],
  tracesSampleRate: process?.env && process.env.DEBUG === 'true' ? 1.0 : 0.1,
  environment: process?.env && process.env.NODE_ENV
});

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const { user, exp } = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(user));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = '/';
  }
}

const LoginContainer = () => (
  <div style={{ height: '100vh', backgroundColor: '#FAFAFA' }}>
    <Route exact path='/' component={({ location }) => <Login from={location.state?.from || '/overview'} />} />
    <Route exact path='/login' component={({ location }) => <Login from={location.state?.from || '/overview'} />} />
  </div>
);

const drawerWidth = 250;

const useStyles = (theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: 36,
      marginLeft: 16
    }
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) - 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) - 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  logoContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'start'
    }
  },
  logo: {
    maxHeight: 40,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20
    }
  },
  accountIcon: {
    marginRight: 16
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
});

const DefaultContainer = () => (
  <div>
    <PrivateRoute exact path='/overview' component={Overview} />
    <PrivateRoute exact path='/dashboard' render={() => <Redirect to='/overview' />} />
    <PrivateRoute exact path='/orders/:type' component={OrdersList} />
    <PrivateRoute exact path='/inventory' component={InventoryList} />
    <ErrorBoundary>
      <PrivateRoute exact path='/kiosk/:uuid' component={Kiosk} />
      <PrivateRoute exact path='/kiosk/:uuid/configure' component={KioskEdit} />
      <PrivateRoute exact path='/kiosks' component={KiosksList} />
      <PrivateRoute
        path='/users/:uuid/edit'
        component={({ match }) => <UserEdit uuid={match.params.uuid} />}
      />
      <PrivateRoute exact path='/users' component={() => <UsersList heading='Users' />} />
      <PrivateRoute exact path='/media' component={MediaList} />
      <PrivateRoute exact path='/reports/kiosk-health' component={KiosksHealth} />
      <PrivateRoute exact path='/reports/incidents' component={IncidentReports} />
      <PrivateRoute exact path='/reports/incidents/:correlationId' component={IncidentReport} />
      <PrivateRoute exact path='/operators' component={() => <OperatorsList heading='Operators' />} />
      <PrivateRoute
        path='/operators/:operatorId/edit'
        component={({ match }) => <OperatorEdit operatorId={match.params.operatorId} />}
      />
      <PrivateRoute exact path='/organizations' component={() => <OrganizationsList heading='Organizations' />} />
      <PrivateRoute
        path='/organizations/:organizationId/edit'
        component={({ match }) => <OrganizationEdit organizationId={match.params.organizationId} />}
      />
      <PrivateRoute exact path='/companies' component={() => <CompaniesList heading='Companies' />} />
      <PrivateRoute
        path='/companies/:companyId/edit'
        component={({ match }) => <CompanyEdit companyId={match.params.companyId} />}
      />
    </ErrorBoundary>
    <Route
      path='/about-us'
      component={() => {
        window.location.href = 'https://dropwater.co';
        return null;
      }}
    />
    <FloatingActionButton />
  </div>
);

class App extends Component {
  render() {
    const { classes, theme } = this.props;
    return (
      <Provider store={store}>
        <Router>
          <div className={classes.root}>
            <NavDrawer classes={classes} theme={theme} store={store} />
            <main className={classes.content} style={{ backgroundColor: '#F7F7F7' }}>
              <div className={classes.toolbar} />
              <div className='App' style={{ backgroundColor: '#F7F7F7' }}>
                <Switch>
                  <Route exact path='/' component={LoginContainer} />
                  <Route exact path='/login' component={LoginContainer} />
                  <Route path='/overview' component={DefaultContainer} />
                  <Route path='/dashboard' component={DefaultContainer} />
                  <Route path='/orders/:type' component={DefaultContainer} />
                  <Route path='/inventory' component={DefaultContainer} />
                  <Route path='/kiosk/:uuid' component={DefaultContainer} />
                  <Route path='/kiosks' component={DefaultContainer} />
                  <Route path='/users/:uuid/edit' component={DefaultContainer} />
                  <Route path='/users' component={DefaultContainer} />
                  <Route path='/media' component={DefaultContainer} />
                  <Route path='/about-us' component={DefaultContainer} />
                  <Route path='/reports/kiosk-health' component={DefaultContainer} />
                  <Route path='/reports/incidents/:correlationId' component={DefaultContainer} />
                  <Route path='/reports/incidents' component={DefaultContainer} />
                  <Route exact path='/operators' component={DefaultContainer} />
                  <Route path='/operators/:operatorId/edit' component={DefaultContainer} />
                  <Route exact path='/organizations' component={DefaultContainer} />
                  <Route path='/organizations/:organizationId/edit' component={DefaultContainer} />
                  <Route exact path='/companies' component={DefaultContainer} />
                  <Route path='/companies/:companyId/edit' component={DefaultContainer} />
                  <Route path='*' component={NotFound} />
                </Switch>
              </div>
            </main>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(App);
