import { useState, useEffect, useCallback } from 'react';
import UserRole from '../../common/UserRole';
import { useAppSelector } from '../../hooks/storeHooks';
import KioskDataAccessService from './KioskDataAccessService';
import IKiosk from './IKiosk';

type KioskDataOptions = {
  companyObjectId?: string;
  companyOnlyKiosks: boolean;
};

const useKiosksData = ({ companyObjectId, companyOnlyKiosks }: KioskDataOptions): IKiosk[] | undefined => {
  const [kiosks, setKiosks] = useState<IKiosk[]>();
  const user = useAppSelector((store) => store.auth?.user);

  const getKiosks = useCallback(async () => {
    if (companyOnlyKiosks && (!companyObjectId || companyObjectId === '')) {
      console.warn(`no companyObjectId, not requesting kiosks`);
      return;
    }
    const kioskService = new KioskDataAccessService();
    setKiosks(await kioskService.getAllEntities({ companyId: companyObjectId }));
  }, [companyObjectId, companyOnlyKiosks]);

  useEffect(() => {
    if (!companyObjectId && companyOnlyKiosks) {
      setKiosks(undefined);
      return undefined;
    }
    if (user?.role === UserRole.DropSuperAdmin) {
      getKiosks();
    }
  }, [getKiosks, user, companyObjectId, companyOnlyKiosks]);

  return kiosks;
};

export default useKiosksData;
