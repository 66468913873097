enum UserEntityStrings {
  KioskSelectDescription = 'Assign kiosks to user. Change role to Machine Super Admin if user should access all kiosks in company.',
  NotificationPreferencesHeading = 'Notification Preferences',
  ReportsOrdersTitle = 'Weekly Order Summary Reports',
  ReportsOrdersDescription = 'Receive weekly order reports sent to your email address',
  DayOfWeekLabel = 'Day of week to receive report',
  DayOfWeekDescription = 'Day of week to receive report',
  HourOfDayLabel = 'Hour of day to receive report',
  TimezoneLabel = 'Your Timezone',
  DashboardAccessTitle = 'Dashboard Access',
  KioskAccessTitle = 'Kiosk Maintenance Access',
  PermissionsTitle = 'Permissions'
}

export default UserEntityStrings;
