import { textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import DataColumn from '../../../components/table/DataColumn';
import ICompany from '../../../services/Company/ICompany';
import CompanyColumnKeys from './CompanyColumnKeys';

const CompaniesTableConfig = (): DataColumn<ICompany, CompanyColumnKeys>[] => {
  return [
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter()
    },
    {
      dataField: 'city',
      text: 'City',
      filter: textFilter()
    },
    {
      dataField: 'state',
      text: 'State',
      filter: textFilter()
    },
    {
      dataField: 'date_created',
      text: 'Created',
      sort: false,
      formatter: (cell: string) => (cell ? new Date(cell).toLocaleDateString('en-US') : ''),
      filter: dateFilter({})
    }
  ];
};

export default CompaniesTableConfig;
