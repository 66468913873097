import EntityCreate from '../../EntityManagement/EntityCreate/EntityCreate';
import EntityType from '../../EntityManagement/EntityType';
import IEntityCreateComponent from '../../EntityManagement/EntityCreate/IEntityCreateComponent';
import IUser from '../../../services/User/IUser';
import UserService from '../../../services/User/UserService';
import GenericEntity from '../../../services/GenericEntity/GenericEntity';
import { useEntityManagementContext } from '../../EntityManagement/context/useEntityManagementContext';
import buildInitialEntity from '../../EntityManagement/EntityCreate/buildInitialEntity';
import { useUserFormState } from '../UserForm/useUserFormState';

const UserCreate = <ParentType extends GenericEntity>({ onSuccess }: IEntityCreateComponent<IUser>) => {
  const parentEntity = useEntityManagementContext<ParentType>();
  const initialEntity = buildInitialEntity<IUser, ParentType>(parentEntity, {
    [EntityType.Operator]: { targetField: 'operator', sourceField: '_id' },
    [EntityType.Organization]: { targetField: 'organization', sourceField: '_id' },
    [EntityType.Company]: { targetField: 'company', sourceField: '_id' }
  });

  const { formConfig, formState, handleFieldChange, handleBeforeStateUpdate } = useUserFormState({ initialEntity });

  return (
    <EntityCreate<IUser>
      service={new UserService()}
      formConfig={formConfig}
      initialEntity={formState}
      entityType={EntityType.User}
      onSuccess={onSuccess}
      onFieldChange={handleFieldChange}
      onBeforeStateUpdate={handleBeforeStateUpdate}
    />
  );
};

export default UserCreate;
