import { useAppSelector } from '../../hooks/storeHooks';
import useOperatorData from '../../services/Operators/useOperatorData';
import IUser from '../../services/User/IUser';
import UserRole from '../../common/UserRole';
import { OrganizationGeneralFieldConfig } from './OrganizationEdit/OrganizationFieldConfig';

export const useOrganizationFormConfig = () => {
  const user = useAppSelector<IUser>((store) => store.auth?.user);
  const { operators } = useOperatorData();

  const formConfig = OrganizationGeneralFieldConfig(user.role === UserRole.DropSuperAdmin ? operators : []);

  return formConfig;
};
