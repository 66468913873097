import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable, { IRowSelectionConfig } from '../../../components/table/DataTable';
import Dialog from '../../../components/Dialog/Dialog';
import IconLoading from '../../../components/ui/Icons/IconLoading';
import { useEntityData } from '../../../services/GenericEntity/useEntityData';
import stringWithSpacesUppercase from '../../../utils/stringWithSpacesUppercase';
import IEntityList from './IEntityList';
import EntityAction from '../../../services/GenericEntity/EntityAction';
import kebabToTitleCase from '../../../utils/kebabToTitleCase';
import { useAppSelector } from '../../../hooks/storeHooks';
import UserRole from '../../../common/UserRole';
import { allowedUserActions } from '../../../common/Permissions/RolePermissions';
import ListHeader from './ListHeader/ListHeader';
import validateEntityListRelationship from './validateEntityListRelationship';
import { useEntityManagementContext } from '../context/useEntityManagementContext';
import GenericEntity from '../../../services/GenericEntity/GenericEntity';

/**
 * Generic component for displaying a list (DataTable) of entities with options to view, edit, and create new entities.
 *
 * @template T - The type of the entity being managed.
 * @template K - The type of keys in the data columns for the DataTable.
 *
 * @param {IEntityList<T, K>} props - The properties for the EntityList component.
 */
const EntityList = <T extends { _id: string }, K extends string>({
  entityService,
  entityType,
  heading,
  dataColumnConfig,
  createComponent: CreateComponent,
  sortKey,
  filterOptions,
  onDataChange,
  rowLinkConfig = {
    key: '_id',
    suffix: 'edit'
  }
}: IEntityList<T, K>) => {
  const history = useHistory();
  const { entities, fetchEntities, loading, error, deleteEntities } = useEntityData(entityService);
  const [localEntities, setLocalEntities] = useState<T[]>([]);
  const [displayCreateDialog, setDisplayCreateDialog] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState<Array<keyof T>>([]);
  const userRole = useAppSelector((state) => state.auth?.user?.role || UserRole.User);
  const allowedActions = allowedUserActions(userRole, entityType);
  const parentEntity = useEntityManagementContext<T>();

  const sortEntities = useCallback((unsortedEntities: T[], key?: keyof T): T[] => {
    if (!key) return unsortedEntities;
    return [...unsortedEntities].sort((a, b) => String(a[key] ?? '').localeCompare(String(b[key] ?? '')));
  }, []);

  useEffect(() => {
    if (entities) {
      setLocalEntities((prevEntities) => {
        const mergedEntities = [...prevEntities, ...entities];
        const uniqueEntities = Array.from(new Map(mergedEntities.map((e) => [e._id, e])).values());
        return sortEntities(uniqueEntities, sortKey);
      });
    }
  }, [entities, sortEntities, sortKey]);

  useEffect(() => {
    fetchEntities(filterOptions);
  }, [fetchEntities, filterOptions]);

  const toggleCreateDialog = (shouldDisplay: boolean) => {
    setDisplayCreateDialog(shouldDisplay);
  };

  const handleCreateSuccess = (newEntity?: T) => {
    if (!newEntity) return;
    const shouldDisplayInList = validateEntityListRelationship(
      newEntity,
      entityType,
      parentEntity?.state?.entity as GenericEntity,
      parentEntity?.entityType
    );

    if (shouldDisplayInList) setLocalEntities((prevEntities) => sortEntities([...prevEntities, newEntity], sortKey));
    toggleCreateDialog(false);
  };

  const linkRowToAccount = {
    onClick: (_e: React.MouseEvent, row: T) => {
      try {
        if (!rowLinkConfig) {
          throw new Error(`Missing rowLinkConfig for EntityList row link ${row._id}`);
        }
        history.push(
          `/${[rowLinkConfig?.path, row?.[rowLinkConfig?.key], rowLinkConfig?.suffix].filter((i) => i).join('/')}`
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (onDataChange && localEntities) {
      onDataChange(localEntities);
    }
  }, [localEntities, onDataChange]);

  const confirmAction = (action: EntityAction, entitiesAffected: number) =>
    window.confirm(
      `Are you sure you want to ${action} ${entitiesAffected} ${kebabToTitleCase(entityType)}${
        entitiesAffected > 1 ? 's' : ''
      }?`
    );

  const handleEntityAction = (action: EntityAction, keys: Array<keyof T> = []): unknown => {
    const actionHandler: Record<EntityAction, (keys: Array<keyof T>) => unknown> = {
      [EntityAction.Create]: () => toggleCreateDialog(true),
      [EntityAction.Delete]: async (keys: Array<keyof T>) => {
        if (!deleteEntities || !confirmAction(action, keys.length)) return null;
        const deletedIds = await deleteEntities(keys);
        if (deletedIds?.length) {
          setLocalEntities((prevEntities) =>
            prevEntities.filter((entity) => !deletedIds.includes(entity._id as keyof T))
          );
        } else {
          console.warn('No entities were deleted.');
        }
      }
    };
    return actionHandler[action](keys);
  };

  const entityRowSelectionConfig: IRowSelectionConfig<T> | undefined =
    allowedActions?.length > 0
      ? { inputType: 'checkbox', onRowSelectionChange: (keys) => setSelectedEntities(keys) }
      : undefined;

  return (
    <div>
      <div>
        <ListHeader<T>
          entityType={entityType}
          heading={heading}
          actions={allowedActions}
          onActionClick={(action, keys) => handleEntityAction(action, keys)}
          selectedEntities={selectedEntities}
        />
        <DataTable<T, K>
          data={localEntities}
          loading={loading}
          rowEvents={linkRowToAccount}
          totalSize={localEntities.length}
          contextName={String(stringWithSpacesUppercase(entityType))}
          dataColumnConfig={dataColumnConfig}
          rowSelectionConfig={entityRowSelectionConfig}
        />
        {loading && <IconLoading width={20} height={20} />}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
      {CreateComponent && (
        <Dialog display={displayCreateDialog} onClose={() => toggleCreateDialog(false)}>
          <CreateComponent onSuccess={handleCreateSuccess} />
        </Dialog>
      )}
    </div>
  );
};
export default EntityList;
