import { useState, useCallback } from 'react';
import useUserFormConfig from './useUserFormConfig';
import useResetEntityRelationships from '../../EntityManagement/EntityForm/useEntityRelationshipReset';
import IUser from '../../../services/User/IUser';

export const useUserFormState = ({
  initialEntity,
  excludeKeys,
  includeKeys
}: {
  initialEntity: Partial<IUser> | null;
  excludeKeys?: Array<keyof IUser>;
  includeKeys?: Array<keyof IUser>;
}) => {
  const [formState, setFormState] = useState<Partial<IUser | null>>(initialEntity);
  const formConfig = useUserFormConfig({ formState, excludeKeys, includeKeys });

  const handleFieldChange = useCallback((updatedFormState: Partial<IUser>) => {
    setFormState((prevState) => ({ ...prevState, ...updatedFormState }));
  }, []);

  const handleBeforeStateUpdate = useResetEntityRelationships<IUser>();

  return { formState, formConfig, handleFieldChange, handleBeforeStateUpdate };
};
