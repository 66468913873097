enum KioskEditTabReference {
  Settings = '1',
  PeripheralCommunication = '2',
  Features = '3',
  System = '4',
  Advanced = '5',
  General = '6'
}

export default KioskEditTabReference;
