import { useState, useEffect, useCallback } from 'react';
import OrganizationService from './OrganizationService';
import IOrganization from './IOrganization';

interface IUseOrganizationData {
  operatorId?: string;
}

const sortOrganizations = (orgs: IOrganization[]): IOrganization[] => {
  return orgs.sort((a, b) => a.name.localeCompare(b.name));
};

const useOrganizationData = ({ operatorId }: IUseOrganizationData) => {
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchOrganizations = useCallback(async () => {
    setLoading(true);
    try {
      const data = await new OrganizationService().getAllEntities({ operatorId });
      setOrganizations(sortOrganizations(data));
    } catch (err) {
      setError('Failed to load organizations');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [operatorId]);

  const fetchNewOrganizations = useCallback(async () => {
    setLoading(true);
    try {
      const data = await new OrganizationService().getAllEntities({ operatorId });
      setOrganizations((prevOrganizations) => {
        const newOrganizations = data.filter((newOrg) => !prevOrganizations.some((org) => org._id === newOrg._id));
        const mergedOrganizations = [...prevOrganizations, ...newOrganizations];
        return sortOrganizations(mergedOrganizations);
      });
    } catch (err) {
      setError('Failed to fetch new organizations');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [operatorId]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  return { organizations, loading, error, fetchNewOrganizations };
};

export default useOrganizationData;
