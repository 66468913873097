import PageHeading from '../../../../components/Heading/PageHeading';
import EntityAction from '../../../../services/GenericEntity/EntityAction';
import EntityType from '../../EntityType';
import EntityListActions from '../EntityListActions/EntityListActions';
import styles from './ListHeader.module.css';

interface IListHeader<T> {
  selectedEntities: (keyof T)[];
  heading?: string;
  /**
   * Array of actions to enable for the list.
   */
  actions: EntityAction[];
  /**
   * Callback triggered when an action is clicked.
   *
   * @param action - The action that was clicked.
   * @param selectedKeys - The keys of the selected rows.
   */
  onActionClick: (action: EntityAction, selectedKeys: Array<keyof T>) => void;
  entityType: EntityType;
}

const ListHeader = <T extends unknown>({
  heading,
  selectedEntities,
  actions,
  entityType,
  onActionClick
}: IListHeader<T>) => {
  return (
    <div className={styles.ListHeader}>
      <div>{heading && <PageHeading heading={heading} />}</div>
      <div className={styles.actions}>
        <EntityListActions
          selectedKeys={selectedEntities}
          actions={actions}
          onActionClick={(action, keys) => onActionClick(action, keys)}
          entityType={entityType}
        />
      </div>
    </div>
  );
};

export default ListHeader;
