import GenericEntity from '../../../services/GenericEntity/GenericEntity';
import EntityType from '../EntityType';

type EntityRelationshipMap = {
  [EntityType.Company]: 'operator' | 'organization';
  [EntityType.User]: 'operator' | 'organization' | 'company';
  [EntityType.Kiosk]: 'operator' | 'company';
  [EntityType.Organization]: 'operator';
};

type RelationshipField = EntityRelationshipMap[keyof EntityRelationshipMap];

/**
 * Validates if a newly created entity should be displayed in the current entity list
 * based on its relationship with the parent context.
 *
 * For example:
 * - If viewing Users under Operator A, a new User created for Operator B should not be shown
 * - If viewing Companies under Organization A, a new Company created for Organization B should not be shown
 *
 * @template T - The type of the newly created entity
 * @template ParentType - The type of the parent entity in the list context
 *
 * @param newEntity - The newly created entity to validate
 * @param parentEntity - The parent entity from the current list context
 * @param listEntityType - The type of entity being listed
 * @param parentEntityType - The type of the parent entity
 *
 * @returns boolean - True if the entity should be displayed in the current list
 */
const validateEntityListRelationship = <T extends GenericEntity, ParentType extends GenericEntity>(
  newEntity: T,
  listEntityType: EntityType,
  parentEntity?: ParentType,
  parentEntityType?: EntityType
): boolean => {
  if (!parentEntity || !parentEntityType) return true;

  const relationshipField = getRequiredRelationshipField(listEntityType, parentEntityType);
  if (!relationshipField) return true;

  const parentValue = parentEntity._id;
  const newEntityValue = newEntity[relationshipField];

  return parentValue === newEntityValue;
};

/**
 * Gets the field name that should match between the parent and child entity
 * based on their entity types
 */
const getRequiredRelationshipField = (
  listEntityType: EntityType,
  parentEntityType: EntityType
): RelationshipField | null => {
  const relationshipMap: Partial<Record<EntityType, Partial<Record<EntityType, RelationshipField>>>> = {
    [EntityType.User]: {
      [EntityType.Operator]: 'operator',
      [EntityType.Organization]: 'organization',
      [EntityType.Company]: 'company'
    },
    [EntityType.Company]: {
      [EntityType.Operator]: 'operator',
      [EntityType.Organization]: 'organization'
    },
    [EntityType.Organization]: {
      [EntityType.Operator]: 'operator'
    },
    [EntityType.Kiosk]: {
      [EntityType.Company]: 'company',
      [EntityType.Operator]: 'operator'
    }
  };

  return relationshipMap[listEntityType]?.[parentEntityType] ?? null;
};

export default validateEntityListRelationship;
