import ConfigService from '../ConfigService';
import EntityDataAccessService from '../GenericEntity/EntityDataAccessService';
import ApiService from '../HttpClient/ApiService';
import ICompany from './ICompany';

export default class CompanyService implements EntityDataAccessService<ICompany> {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getAllEntities = ({
    organizationId,
    operatorId
  }: {
    organizationId?: string;
    operatorId?: string;
  }): Promise<ICompany[]> => {
    const requestParams = new URLSearchParams();
    organizationId && requestParams.append('organizationId', organizationId);
    operatorId && requestParams.append('operatorId', operatorId);
    return this.apiService.request<ICompany[]>('companies', 'GET', requestParams);
  };

  getEntityById = (id: string): Promise<ICompany> => this.apiService.request<ICompany>(`companies/${id}`, 'GET');

  createEntity = (data: Partial<ICompany>): Promise<ICompany> =>
    this.apiService.request<ICompany>('companies', 'POST', undefined, data);

  deleteEntity = async (id: string): Promise<void> => this.apiService.request(`companies/${id}`, 'DELETE');

  updateEntity = (data: Partial<ICompany>): Promise<ICompany> => {
    if (!data._id) throw new Error("Entity must have an 'id' to be updated");
    return this.apiService.request<ICompany>(`companies/${data._id}`, 'PUT', undefined, data);
  };

  deleteEntities = (ids: string[]): Promise<void> => {
    console.log(`Deleting Companies: ${ids.join(', ')}`);
    const body = {
      ids
    };
    return this.apiService.request(`companies`, 'DELETE', undefined, body);
  };
}
