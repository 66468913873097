import BackButton from '../Button/BackButton';
import styles from './PageHeading.module.css';

interface IPageHeading {
  heading: string;
  displayBackButton?: boolean;
}

const PageHeading: React.FC<IPageHeading> = ({ heading, displayBackButton }) => {
  return (
    <div className={styles.PageHeading}>
      <h1
        style={{
          fontWeight: 400,
          fontSize: '24px',
          margin: '15px 0'
        }}
      >
        {heading}
      </h1>
      {displayBackButton && <BackButton />}
    </div>
  );
};

export default PageHeading;
