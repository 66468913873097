import ConfigService from '../ConfigService';
import ApiService from '../HttpClient/ApiService';
import IUser from './IUser';
import EntityDataAccessService from '../GenericEntity/EntityDataAccessService';

export default class UserService implements EntityDataAccessService<IUser> {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getEntityById = (uuid: string): Promise<IUser> => this.apiService.request(`users/${uuid}`, 'GET');

  getAllEntities = ({
    organizationId,
    companyId,
    operatorId
  }: {
    companyId?: string;
    organizationId?: string;
    operatorId?: string;
  }): Promise<IUser[]> => {
    const requestParams = new URLSearchParams();
    companyId && requestParams.append('companyId', companyId);
    organizationId && requestParams.append('organizationId', organizationId);
    operatorId && requestParams.append('operatorId', operatorId);
    return this.apiService.request<IUser[]>('users', 'GET', requestParams);
  };

  updateEntity = (user: Partial<IUser>): Promise<IUser> =>
    this.apiService.request<IUser>(`users/${user.uuid}`, 'PUT', undefined, user);

  createEntity = async (user: Partial<IUser>): Promise<IUser> =>
    this.apiService.request<IUser>('users', 'POST', undefined, user);

  deleteEntity = async (uuid: string): Promise<void> => this.apiService.request(`users/${uuid}`, 'DELETE');

  deleteEntities = (ids: string[]): Promise<void> => {
    console.log(`Deleting Users: ${ids.join(', ')}`);
    const body = {
      ids
    };
    return this.apiService.request(`users`, 'DELETE', undefined, body);
  };
}
