import { createContext, ReactNode } from 'react';
import IEntityManagementContext from './IEntityManagementContext';

export const EntityManagementContext = createContext<IEntityManagementContext<any> | undefined>(undefined);

export function EntityManagementContextProvider<T>({
  children,
  value
}: {
  children: ReactNode;
  value: IEntityManagementContext<T>;
}) {
  return <EntityManagementContext.Provider value={value}>{children}</EntityManagementContext.Provider>;
}
