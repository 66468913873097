import { useCallback, useState, useEffect } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import IKiosk from '../../../../../services/Kiosk/IKiosk';
import IconLoading from '../../../../../components/ui/Icons/IconLoading';
import KioskAccessStrings from './KioskAccessGeneratorStrings';
import KioskDataAccessService from '../../../../../services/Kiosk/KioskDataAccessService';

interface IKioskSelect {
  value: string;
  name: string;
  label: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  companyId?: string;
}

const KioskSelect: React.FC<IKioskSelect> = ({ value, name, label, disabled, onChange }) => {
  const [kiosks, setKiosks] = useState<IKiosk[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const getKiosks = useCallback(async () => {
    setLoading(true);
    try {
      const kioskData = await new KioskDataAccessService().getAllEntities({});
      setKiosks(kioskData);
    } catch (error) {
      setError('Sorry, failed to retrieve kiosks list');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getKiosks();
  }, [getKiosks]);

  return (
    <>
      {loading && <IconLoading width={50} height={50} />}
      {!loading && kiosks?.length === 0 && <> {KioskAccessStrings.NoKiosksAvailable} </>}
      {error && error}
      {kiosks && !loading && (
        <TextField
          select
          size='small'
          variant='outlined'
          fullWidth={true}
          required={true}
          id={name}
          label={label}
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          style={{ margin: '20px 0', maxWidth: '90vw' }}
          disabled={disabled}
        >
          {kiosks
            ?.sort((a, b) => a.kiosk_ID.localeCompare(b.kiosk_ID))
            .map((kiosk, index) => (
              <MenuItem key={index} value={kiosk.kiosk_ID}>
                {kiosk.kiosk_ID} {kiosk?.alias && `(${kiosk.alias})`} {kiosk?.location && kiosk.location}
              </MenuItem>
            ))}
        </TextField>
      )}
    </>
  );
};

export default KioskSelect;
