import React from 'react';
import EntityEditScreen from '../../EntityManagement/EntityEdit/EntityEditScreen';
import EntityForm from '../../EntityManagement/EntityForm/EntityForm';
import EntityType from '../../EntityManagement/EntityType';
import IUser from '../../../services/User/IUser';
import UserService from '../../../services/User/UserService';
import { useUserFormState } from '../UserForm/useUserFormState';
import UserSecurity from './UserSecurity';
import UserPermissions from './UserPermissions/UserPermissions';
import UserNotifications from './UserNotifications';

interface IUserEdit {
  uuid: string;
}

const UserEdit: React.FC<IUserEdit> = ({ uuid }) => {
  const { formConfig, handleFieldChange, handleBeforeStateUpdate } = useUserFormState({
    initialEntity: null,
    excludeKeys: ['password', 'role', 'kioskIds']
  });

  const UserEditTabsConfig = (uuid: string) => [
    {
      label: 'General',
      key: 'general',
      component: <EntityForm mode='edit' fieldConfig={formConfig} onFieldChange={handleFieldChange} />
    },
    {
      label: 'Security',
      key: 'security',
      component: <UserSecurity userId={uuid} />
    },
    {
      label: 'Permissions',
      key: 'permissions',
      component: <UserPermissions userId={uuid} />
    },
    {
      label: 'Notifications',
      key: 'notifications',
      component: <UserNotifications />
    }
  ];

  return (
    <EntityEditScreen<IUser>
      entityId={uuid}
      tabConfig={UserEditTabsConfig(uuid)}
      service={new UserService()}
      entityType={EntityType.User}
      headingKey={'name'}
      displayBackButton
      onBeforeStateUpdate={handleBeforeStateUpdate}
    />
  );
};

export default UserEdit;
