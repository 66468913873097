export default class ObjectUtility {
  /**
   * Adds or removes a value in an array.
   * If the value exists in the array it's removed; otherwise, it's added.
   *
   * @template T - The type of the elements in the array.
   * @param array - The array to toggle the value in.
   * @param value - The value to add or remove.
   * @returns A new array with the value added or removed.
   */
  static toggleArrayValue<T>(array: T[], value: T): T[] {
    return array.includes(value) ? array.filter((item) => item !== value) : [...array, value];
  }

  /**
   * **Used for dot notated paths**
   *
   * Retrieves the value at the specified dot-notated path in an object.
   *
   * @template T - The type of the object to retrieve the value from.
   * @template R - The expected type of the returned value.
   * @param obj - The object to retrieve the value from.
   * @param path - The dot-notated path to the desired property (e.g., "a.b.c").
   * @returns The value at the specified path, or undefined if the path does not exist.
   */
  static getValueByDotNotatedPath<T, R = unknown>(obj: T | null, path: string): R | undefined {
    if (!obj) return undefined;
    return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj) as
      | R
      | undefined;
  }

  /**
   * Sets a value at the specified dot-notated path in an object.
   * If the path does not exist, intermediate objects will be created as needed.
   *
   * @template T - The type of the object to set the value in.
   * @param obj - The object to modify.
   * @param path - The dot-notated path where the value should be set (e.g., "a.b.c").
   * @param value - The value to set at the specified path.
   */
  static setValueByDotNotatedPath<T>(obj: T, path: string, value: any): void {
    if (!path) return undefined;
    const keys = path.split('.');
    let current: any = obj;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        current[key] = current[key] || {};
        current = current[key];
      }
    });
  }
}
