import { selectFilter, textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import kebabToTitleCase from '../../../utils/kebabToTitleCase';
import UserRole from '../../../common/UserRole';
import DataColumn from '../../../components/table/DataColumn';
import IUser from '../../../services/User/IUser';
import KiosksList from './KiosksList';

const userRoleOptions = Object.entries(UserRole).reduce((options, [key, value]) => {
  options[value] = key;
  return options;
}, {});

type UserKeys = keyof IUser | 'company.name';

const UsersTableConfig = ({
  allKioskIds,
  allCompanies
}: {
  allKioskIds: string[];
  allCompanies: string[];
}): DataColumn<IUser, UserKeys>[] => {
  const kioskOptions =
    allKioskIds &&
    allKioskIds.reduce((options, id) => {
      options[id] = id;
      return options;
    }, {});

  const companyOptions =
    allCompanies &&
    allCompanies.reduce((options, name) => {
      options[name] = name;
      return options;
    }, {});

  return [
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter()
    },
    {
      dataField: 'email',
      text: 'Email',
      filter: textFilter()
    },
    {
      dataField: 'kioskIds',
      text: 'Kiosks',
      formatter: (cell: string[], row: any, rowIndex: number, formatExtraData: any) => (
        <KiosksList kioskIds={cell} role={row.role} />
      ),
      filter: selectFilter({
        options: kioskOptions
      })
    },
    {
      dataField: 'role',
      text: 'Role',
      formatter: (cell: string) => (cell ? <>{kebabToTitleCase(cell)}</> : undefined),
      filter: selectFilter({
        options: userRoleOptions
      })
    },
    {
      dataField: 'company.name',
      text: 'Company',
      filter: selectFilter({
        options: companyOptions
      })
    },
    {
      dataField: 'updatedAt',
      text: 'Date Updated',
      formatter: (cell: string) => (cell ? new Date(cell).toLocaleDateString('en-US') : 'No updates'),
      filter: dateFilter({})
    },
    {
      dataField: 'createdAt',
      text: 'Date Created',
      formatter: (cell: string) => new Date(cell).toLocaleDateString('en-US'),
      filter: dateFilter({})
    }
  ];
};

export default UsersTableConfig;
