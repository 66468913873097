import React from 'react';
import KioskType from '../../common/Kiosk/KioskType';
import DrinkPrice from '../../services/Kiosk/IDrinkPrice';

interface IKioskPricingTable {
  kioskType?: KioskType;
  drinkPrice: DrinkPrice;
}

const formatPrice = (price?: number) => (price ? `$${(price / 100).toFixed(2)}` : '-');

const KioskPricingTable: React.FC<IKioskPricingTable> = ({ kioskType, drinkPrice }) => {
  return (
    <table style={{ tableLayout: 'fixed' }} className='kiosk-pricing-table'>
      <thead>
        <tr>
          <th scope='col' />
          <th scope='col' className='center-align'>
            Water
          </th>
          <th scope='col' className='center-align'>
            Flavor
          </th>
          <th scope='col' className='center-align'>
            Supplement
          </th>
        </tr>
      </thead>
      <tbody>
        {kioskType === KioskType.Duo && (
          <tr>
            <th scope='row'>Drop Bottle</th>
            <td className='center-align'>{formatPrice(drinkPrice?.dropBottleWater)}</td>
            <td className='center-align'>{formatPrice(drinkPrice?.dropBottleFlavor)}</td>
            <td className='center-align'>{formatPrice(drinkPrice?.dropBottleSupplement)}</td>
          </tr>
        )}
        <tr>
          <th scope='row'>Refill</th>
          <td className='center-align'>{formatPrice(drinkPrice?.refillWater)}</td>
          <td className='center-align'>{formatPrice(drinkPrice?.refillFlavor)}</td>
          <td className='center-align'>{formatPrice(drinkPrice?.refillSupplement)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default KioskPricingTable;
