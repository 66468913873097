import ButtonRoundedPrimary from './ButtonRounded/ButtonRoundedPrimary';

interface ISubmitButtonProps {
  disabled: boolean;
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const SubmitButton: React.FC<ISubmitButtonProps> = ({ disabled, label, onClick }) => {
  return <ButtonRoundedPrimary disabled={disabled} onClick={onClick} label={label} type='submit' />;
};

export default SubmitButton;
