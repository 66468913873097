import React from 'react';
import EntityEditScreen from '../../EntityManagement/EntityEdit/EntityEditScreen';
import OperatorService from '../../../services/Operators/OperatorService';
import EntityForm from '../../EntityManagement/EntityForm/EntityForm';
import { OperatorGeneralFieldConfig } from './OperatorFieldConfig';
import UsersList from '../../Users/UsersList';
import OrganizationsList from '../../Organizations/OrganizationsList';
import CompaniesList from '../../Companies/CompaniesList';
import EntityType from '../../EntityManagement/EntityType';
import KiosksList from '../../Kiosk/KiosksList/KiosksList';

const OperatorEditTabsConfig = (operatorId: string) => [
  { label: 'General', key: 'general', component: <EntityForm mode='edit' fieldConfig={OperatorGeneralFieldConfig} /> },
  { label: 'Users', key: 'users', component: <UsersList operatorId={operatorId} heading='Users' /> },
  {
    label: 'Organizations',
    key: 'organizations',
    component: <OrganizationsList operatorId={operatorId} heading='Organizations' />
  },
  { label: 'Companies', key: 'companies', component: <CompaniesList operatorId={operatorId} heading='Companies' /> },
  {
    label: 'Kiosks',
    key: 'kiosks',
    component: <KiosksList operatorId={operatorId} heading='Kiosks' excludeKeys={['operator']} />
  }
];

interface IOperatorEdit {
  operatorId: string;
}

const OperatorEdit: React.FC<IOperatorEdit> = ({ operatorId }) => {
  return (
    <EntityEditScreen
      entityId={operatorId}
      tabConfig={OperatorEditTabsConfig(operatorId)}
      service={new OperatorService()}
      entityType={EntityType.Operator}
      headingKey={'name'}
      displayBackButton
    />
  );
};

export default OperatorEdit;
