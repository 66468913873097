import React from 'react';
import EntityForm from '../../EntityManagement/EntityForm/EntityForm';
import IUser from '../../../services/User/IUser';
import { FieldConfig } from '../../../components/Form/FormBuilder/FieldConfig';
import { useAppSelector } from '../../../hooks/storeHooks';
import SectionHeading from '../../../components/Heading/SectionHeading/SectionHeading';
import UserEntityStrings from '../UserEntityStrings';
import DayOfWeek from '../../../common/DayOfWeek';
import moment from 'moment';
import HoursOfDay from '../../../common/HourOfDay';

interface IUserNotifications {}

const UserNotifications: React.FC<IUserNotifications> = () => {
  const loggedInUser = useAppSelector((store) => store.auth?.user);

  const allowedTimezones = [
    { zone: 'America/Los_Angeles', name: 'US Pacific Time' },
    { zone: 'America/Denver', name: 'US Mountain Time' },
    { zone: 'America/New_York', name: 'US Eastern Time' }
  ];

  const timezones = allowedTimezones.map(({ zone, name }) => {
    const offset = moment.tz(zone).utcOffset() / 60;
    const formattedOffset = `(GMT${offset >= 0 ? `+${offset}` : offset})`;
    return {
      label: `${formattedOffset} ${name}`,
      value: zone
    };
  });

  const formConfig: FieldConfig<IUser>[] = [
    {
      key: 'notificationPreferences.kioskOrderSummary.enabled',
      type: 'boolean',
      required: true,
      label: 'Receive Weekly Order Summary Reports',
      description: `Receive weekly order reports sent to your email address: ${loggedInUser.email}`
    },
    {
      key: 'notificationPreferences.kioskOrderSummary.hourOfDay',
      label: UserEntityStrings.HourOfDayLabel,
      type: 'select',
      required: true,
      options: Object.values(HoursOfDay).map((day, index) => ({ label: day, value: index })),
      renderCondition: (user) => user.notificationPreferences?.kioskOrderSummary.enabled === true
    },
    {
      key: 'notificationPreferences.kioskOrderSummary.dayOfWeek',
      label: UserEntityStrings.DayOfWeekLabel,
      type: 'select',
      required: true,
      options: Object.values(DayOfWeek).map((day) => ({ label: day, value: day })),
      renderCondition: (user) => user.notificationPreferences?.kioskOrderSummary.enabled === true
    },
    {
      key: 'timezone',
      label: UserEntityStrings.TimezoneLabel,
      type: 'select',
      required: true,
      options: timezones,
      renderCondition: (user) => user.notificationPreferences?.kioskOrderSummary.enabled === true
    }
  ];

  return (
    <>
      <SectionHeading heading={UserEntityStrings.NotificationPreferencesHeading} />
      <EntityForm<IUser> mode='edit' fieldConfig={formConfig} />
    </>
  );
};
export default UserNotifications;
