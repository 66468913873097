import { BottlePricing, RefillPricing } from '../../../../common/Kiosk/KioskSettings';
import KioskType from '../../../../common/Kiosk/KioskType';
import { FieldConfig } from '../../../../components/Form/FormBuilder/FieldConfig';
import {
  BottleInventorySettings,
  CO2InventorySettings,
  InventoryAlarmsConfig,
  InventoryCalculation
} from '../Settings/KioskInventoryControl/InventoryControlSettings';

export type KioskFieldConfig<T> = FieldConfig<T, keyof T> & {
  kioskTypes: KioskType[];
};

export const RefillPricingFieldConfig: KioskFieldConfig<RefillPricing>[] = [
  {
    key: 'caffeine',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  {
    key: 'flavor',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  { key: 'sparkling', required: true, type: 'number', kioskTypes: [KioskType.Refill] },
  {
    key: 'minimumCharge',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  {
    key: 'preAuthCharge',
    required: true,
    type: 'number',
    kioskTypes: []
  }
];

export const BottlePricingFieldConfig: KioskFieldConfig<BottlePricing>[] = [
  {
    key: 'base',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  {
    key: 'caffeine',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  {
    key: 'flavor',
    required: false,
    type: 'number',
    kioskTypes: []
  }
];

export const BottleInventoryControlFieldConfig: KioskFieldConfig<BottleInventorySettings>[] = [
  {
    key: 'calculation',
    required: true,
    type: 'enum',
    kioskTypes: [KioskType.Duo],
    enumValues: InventoryCalculation
  }
];

export const InventoryAlarmFieldConfig: KioskFieldConfig<InventoryAlarmsConfig>[] = [
  {
    key: 'low',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  {
    key: 'empty',
    required: true,
    type: 'number',
    kioskTypes: []
  }
];

export const CO2InventoryFieldConfig: KioskFieldConfig<CO2InventorySettings>[] = [
  {
    key: 'enabled',
    required: true,
    type: 'boolean',
    kioskTypes: [KioskType.Refill],
  }
];
