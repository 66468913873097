import { textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import DataColumn from '../../../components/table/DataColumn';
import IOrganization from '../../../services/Organizations/IOrganization';
import OrganizationColumnKeys from './OrganizationColumnKeys';

const OrganizationsTableConfig = (): DataColumn<IOrganization, OrganizationColumnKeys>[] => {
  return [
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'city',
      text: 'City',
      filter: textFilter()
    },
    {
      dataField: 'state',
      text: 'State',
      filter: textFilter()
    },
    {
      dataField: 'updatedAt',
      text: 'Last Updated',
      sort: true,
      formatter: (cell: string) => (cell ? new Date(cell).toLocaleDateString('en-US') : 'No updates'),
      filter: dateFilter({}),
    }
  ];
};

export default OrganizationsTableConfig;
