import React from 'react';
import EntityForm from '../../../EntityManagement/EntityForm/EntityForm';
import IUser from '../../../../services/User/IUser';
import { useAppSelector } from '../../../../hooks/storeHooks';
import SectionHeading from '../../../../components/Heading/SectionHeading/SectionHeading';
import { useUserFormState } from '../../UserForm/useUserFormState';
import UserRole from '../../../../common/UserRole';
import AuthGuard from '../../../../components/auth/AuthGuard';
import { useEntityManagementContext } from '../../../EntityManagement/context/useEntityManagementContext';
import UserRoleDisplayOnly from './UserRoleDisplayOnly';
import UserEntityStrings from '../../UserEntityStrings';

interface IUserSecurity {
  userId?: string;
}

const UserPermissions: React.FC<IUserSecurity> = ({ userId }) => {
  const loggedInUser = useAppSelector((store) => store.auth?.user);
  const accountIsLoggedInUser = loggedInUser.uuid === userId;
  const { formConfig, handleFieldChange } = useUserFormState({
    initialEntity: null,
    includeKeys: ['role', 'kioskIds']
  });

  const userEntity = useEntityManagementContext<IUser>();

  return (
    <>
      <SectionHeading heading={UserEntityStrings.PermissionsTitle} />
      <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin, UserRole.MachineSuperAdmin]} userRole={loggedInUser?.role}>
        {accountIsLoggedInUser ? (
          <UserRoleDisplayOnly userRole={loggedInUser.role} isCurrentUser={accountIsLoggedInUser} />
        ) : (
          <EntityForm<IUser> mode='edit' fieldConfig={formConfig} onFieldChange={handleFieldChange} />
        )}
      </AuthGuard>
      <AuthGuard authorizedRoles={[UserRole.User, UserRole.MachineAdmin]} userRole={loggedInUser?.role}>
        <UserRoleDisplayOnly userRole={userEntity.state.entity?.role} isCurrentUser={accountIsLoggedInUser} />
      </AuthGuard>
    </>
  );
};
export default UserPermissions;
