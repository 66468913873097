import ConfigService from '../ConfigService';
import EntityDataAccessService from '../GenericEntity/EntityDataAccessService';
import ApiService from '../HttpClient/ApiService';
import IOrganization from './IOrganization';

export default class OrganizationService implements EntityDataAccessService<IOrganization> {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getAllEntities = ({ operatorId }: { operatorId?: string }): Promise<IOrganization[]> => {
    const requestParams = new URLSearchParams();
    operatorId && requestParams.append('operatorId', operatorId);
    return this.apiService.request<IOrganization[]>('organizations', 'GET', requestParams);
  };

  getEntityById = (id: string): Promise<IOrganization> =>
    this.apiService.request<IOrganization>(`organizations/${id}`, 'GET');

  createEntity = (data: Partial<IOrganization>): Promise<IOrganization> =>
    this.apiService.request<IOrganization>('organizations', 'POST', undefined, data);

  deleteEntity = (id: string): Promise<void> => this.apiService.request(`organizations/${id}`, 'DELETE');

  updateEntity = (data: Partial<IOrganization>): Promise<IOrganization> => {
    if (!data._id) throw new Error("Entity must have an 'id' to be updated");
    return this.apiService.request<IOrganization>(`organizations/${data._id}`, 'PUT', undefined, data);
  };

  deleteEntities = (ids: string[]): Promise<void> => {
    console.log(`Deleting Organizations: ${ids.join(', ')}`);
    const body = {
      ids
    };
    return this.apiService.request(`organizations`, 'DELETE', undefined, body);
  };
}
