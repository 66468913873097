import EntityType from '../../screens/EntityManagement/EntityType';
import EntityAction from '../../services/GenericEntity/EntityAction';
import UserRole from '../UserRole';

const RolePermissions: Record<UserRole, Record<EntityType, EntityAction[]>> = {
  [UserRole.DropSuperAdmin]: {
    [EntityType.Kiosk]: [EntityAction.Create],
    [EntityType.Operator]: [EntityAction.Create, EntityAction.Delete],
    [EntityType.Organization]: [EntityAction.Create, EntityAction.Delete],
    [EntityType.Company]: [EntityAction.Create, EntityAction.Delete],
    [EntityType.User]: [EntityAction.Create, EntityAction.Delete]
  },
  [UserRole.MachineSuperAdmin]: {
    [EntityType.Kiosk]: [],
    [EntityType.Operator]: [],
    [EntityType.Organization]: [],
    [EntityType.Company]: [],
    [EntityType.User]: []
  },
  [UserRole.MachineAdmin]: {
    [EntityType.Kiosk]: [],
    [EntityType.Operator]: [],
    [EntityType.Organization]: [],
    [EntityType.Company]: [],
    [EntityType.User]: []
  },
  [UserRole.User]: {
    [EntityType.Kiosk]: [],
    [EntityType.Operator]: [],
    [EntityType.Organization]: [],
    [EntityType.Company]: [],
    [EntityType.User]: []
  }
};

export const allowedUserActions = (userRole: UserRole, entityType: EntityType): EntityAction[] =>
  RolePermissions[userRole]?.[entityType] || [];

export default RolePermissions;
