import IOrganization from '../../services/Organizations/IOrganization';
import OrganizationService from '../../services/Organizations/OrganizationService';
import EntityList from '../EntityManagement/EntityList/EntityList';
import EntityType from '../EntityManagement/EntityType';
import OrganizationCreate from './OrganizationCreate/OrganizationCreate';
import OrganizationColumnKeys from './OrganizationsTable/OrganizationColumnKeys';
import OrganizationsTableConfig from './OrganizationsTable/OrganizationsTableConfig';

interface IOrganizationsList {
  operatorId?: string;
  heading?: string;
}

const OrganizationsList: React.FC<IOrganizationsList> = ({ heading, operatorId }) => {
  return (
    <EntityList<IOrganization, OrganizationColumnKeys>
      entityService={new OrganizationService()}
      entityType={EntityType.Organization}
      heading={heading}
      dataColumnConfig={OrganizationsTableConfig()}
      createComponent={OrganizationCreate}
      sortKey='name'
      filterOptions={{
        operatorId
      }}
      rowLinkConfig={{
        path: 'organizations',
        suffix: 'edit',
        key: '_id'
      }}
    />
  );
};

export default OrganizationsList;
