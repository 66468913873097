import { FieldOption } from './FieldConfig';

export default class FieldConfigUtility {
  static createFieldOptions<T>(
    valueKey: keyof T,
    labelKey: keyof T,
    entities?: T[],
  ): FieldOption[] {
    if (!Array.isArray(entities)) return [];
    return entities
      .filter((entity) => entity[valueKey] !== undefined && entity[labelKey] !== undefined)
      .map((entity) => ({
        value: entity[valueKey] as string | number,
        label: entity[labelKey] as string
      }));
  }
}
