import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import { useHistory, useLocation } from 'react-router-dom';
import SubmitButton from '../Button/SubmitButton';

const Login = () => {
  const [formState, setFormState] = useState({
    email: '',
    password: ''
  });
  const [formErrors, setFormErrors] = useState({
    error: undefined
  });
  const history = useHistory();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.isAuthenticated) {
      localStorage.setItem('uuid', auth.user.uuid);
      localStorage.setItem('role', auth.user.role);
      // Redirect authenticated users
      const redirectPath = location.state?.from || '/overview';
      history.push(redirectPath);
    }
  }, [auth.isAuthenticated, auth.user.uuid, auth.user.role, history, location.state?.from]);

  useEffect(() => {
    if (errors) {
      setFormErrors(errors);
    }
  }, [errors]);

  const onChange = (e) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: formState.email,
      password: formState.password
    };
    dispatch(loginUser(userData));
  };

  return (
    <div className='animate__animated animate__fadeInDown'>
      <div style={{ marginTop: '4rem' }} className='row'>
        <div className='col s8 offset-s2'>
          <form onSubmit={onSubmit}>
            <div className='input-field col s12'>
              <input
                onChange={onChange}
                value={formState.email}
                id='email'
                type='email'
                required
              />
              <label htmlFor='email'>Email</label>
              <span className='red-text'>
                {formErrors.email}
                {formErrors.emailnotfound}
              </span>
            </div>
            <div className='input-field col s12'>
              <input
                onChange={onChange}
                value={formState.password}
                id='password'
                type='password'
                required
              />
              <label htmlFor='password'>Password</label>
            </div>
            <div className='col s12'>
              <SubmitButton label='Login' />
            </div>
            <p className='red-text col s12'>{formErrors.error}</p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
